import * as React from 'react';
import { where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  Button,
  Grid,
  LoadingIndicator,
  Localized,
  Typography,
} from 'src/app/components';
import { useAdminContext } from 'src/admin/context';
import { useAppContext } from 'src/app/hooks';
import { NotificationType, OrganisationEmailNotificationType } from 'src/app/constants';
import { FirestoreCollectionData } from 'src/app/container';

import { getOrganisationUserDisplayName } from 'src/app.organisation/lib';

import { EmailNotificationManagementListUser } from './EmailNotificationManagementListUser';

interface EmailPoolUserAddFunctionParams {
  notificationType: OrganisationEmailNotificationType;
  organisationId: string;
  userId: string; // user id to add to the pool
}

// interface EmailPoolUserRemoveFunctionParams {
//   notificationType: OrganisationEmailNotificationType;
//   organisationId: string;
//   userId: string; // user id to remove from the pool
// }

export interface AddUserToNotificationsProps {
  notificationType: OrganisationEmailNotificationType;
  onClose: () => void;
  subscribedUserIds: string[];
  // notificationSettings: OrganisationNotificationSettings;
}

const i18nNS = 'organisation';
const screenId = 'org-settings';
const i18nPath = `${i18nNS}:${screenId}`;
const settingsEmailI18nPath = `${i18nPath}-email`;

export const AddUserToNotifications = ({
  notificationType,
  onClose,
  subscribedUserIds,
}: AddUserToNotificationsProps) => {
  const { createNotification, currentOrganisation, firebase } = useAppContext();
  const { document: organisationDocument } = useAdminContext();
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  if (!currentOrganisation?.id) {
    return null;
  }
  const onAddUser = async (id: string) => {
    if (!firebase || !id || !organisationDocument?.id || !notificationType) {
      return;
    }

    try {
      setIsRequestingUpdate(true);
      const request = httpsCallable(
        getFunctions(firebase),
        'organisationFunction-emailPoolUserAdd',
      );
      const params: EmailPoolUserAddFunctionParams = {
        organisationId: organisationDocument.id,
        notificationType,
        userId: id,
      };
      await request(params);
      createNotification(NotificationType.SUCCESS, 'Success', 5000);
    } catch (err) {
      console.warn('Error: ', err);
      createNotification(NotificationType.ERROR, 'Error', 5000);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  // if (!notificationSettings) {
  //   return null;
  // }

  if (isRequestingUpdate) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container spacing={2}>
      <FirestoreCollectionData
        collectionPath={`organisation/${currentOrganisation.id}/user`}
        queryConstraints={[where('id', 'not-in', [...subscribedUserIds])]}
        renderElements={({ data: users, status }) => {
          return status !== 'success' ? null : (
            <>
              {users?.length ? (
                users.map(({ id }) => (
                  <Grid key={id} item xs={12}>
                    <EmailNotificationManagementListUser
                      onButtonClick={() => onAddUser(id)}
                      buttonLabel={
                        <Localized
                          dictKey={`${settingsEmailI18nPath}.action.user-add.label`}
                        />
                      }
                      userName={
                        organisationDocument
                          ? getOrganisationUserDisplayName(organisationDocument, id)
                          : null
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography>
                    <Localized dictKey={`${settingsEmailI18nPath}.no-user-available`} />
                  </Typography>{' '}
                </Grid>
              )}
            </>
          );
        }}
      />
      <Grid item xs={12}>
        <Button variant={'outlined'} fullWidth onClick={onClose}>
          <Typography>
            <Localized
              dictKey={`${settingsEmailI18nPath}.action.user-add-menu-close.label`}
            />
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
