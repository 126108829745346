import * as React from 'react';
import { navigate } from 'gatsby';
import { Router, useMatch } from '@reach/router';
import { FieldValues } from 'react-hook-form';

import {
  FormChapterInformation,
  FormControl,
  FormErrors,
  FormLayout,
  FormLinkButton,
} from 'src/app/components/RHF';

import { BaseFormProps } from 'src/app.system/components';

import { ButtonTw, ButtonGroup, Grid, PrivateRoute, Localized } from 'src/app/components';

import {
  OrganisationSignupAdditionalInformationFragment,
  OrganisationSignupAdditionalInformationFragmentProps,
  OrganisationSignupBaseInformationFragment,
  OrganisationSignupBaseInformationFragmentProps,
  OrganisationSignupOwnerFragment,
  OrganisationSignupOwnerFragmentProps,
} from './fragments';

import { OrganisationSignupDocumentFormChapter } from './organisationSignupFormChapter.constants';

const i18nPath = 'organisation:/organisation/signup';

const OrganisationSignupFormInitialScreen = () => {
  const onClick = () => {
    navigate(OrganisationSignupDocumentFormChapter.BASE_INFORMATION);
  };
  return (
    <div className='pt-4 text-center md:pt-6'>
      <ButtonTw
        onClick={onClick}
        variant='accent'
        rounded
        size='xl'
        className='w-full sm:w-auto sm:min-w-[270px]'
      >
        <Localized dictKey={`${i18nPath}.action.signup.button.label`} />
      </ButtonTw>
    </div>
  );
};

export interface OrganisationSignupFormProps<T extends FieldValues>
  extends BaseFormProps<T> {
  children?: React.ReactNode;
  routeBasepath: string;
}

export const OrganisationSignupForm = <T extends FieldValues>(
  props: OrganisationSignupFormProps<T>,
) => {
  const { children, formProps, i18nBasePath, routeBasepath } = props;

  const firstChapterPath = `/${routeBasepath}/${OrganisationSignupDocumentFormChapter.BASE_INFORMATION}`;
  const secondChapterPath = `/${routeBasepath}/${OrganisationSignupDocumentFormChapter.OWNER_INFORMATION}`;
  const lastChapterPath = `/${routeBasepath}/${OrganisationSignupDocumentFormChapter.ADDITIONAL_INFORMATION}`;

  const matchFirstChapter = useMatch(firstChapterPath);
  const matchSecondChapter = useMatch(secondChapterPath);
  const matchLastChapter = useMatch(lastChapterPath);
  const isFormValid = true;

  const getPrefixedProperties = (prefix: string, values: string[]) =>
    values.map((v) => `${prefix}.${v}`);

  const firstChapterTriggerProperties = getPrefixedProperties('organisation', [
    'organisationAddressStreetNumber',
    'organisationAddressStreet',
    'organisationCity',
    'organisationCountry',
    'organisationName2',
    'organisationName',
    'organisationPostOfficeBox',
    'organisationZipCode',
  ]);
  const secondChapterTriggerProperties = getPrefixedProperties('owner', [
    'ownerEmail',
    'ownerFirstName',
    'ownerLastName',
    'ownerPassword',
    'ownerPasswordConfirmation',
    'ownerPhone',
  ]);
  const lastChapterTriggerProperties = getPrefixedProperties('additionalInformation', [
    // 'activitiesDescription',
    // 'organisationDescription',
    // TODO: missing fields
  ]);

  const formChapterIds = [
    OrganisationSignupDocumentFormChapter.BASE_INFORMATION,
    OrganisationSignupDocumentFormChapter.OWNER_INFORMATION,
    OrganisationSignupDocumentFormChapter.ADDITIONAL_INFORMATION,
  ];

  const isNotRootPath = Boolean(
    typeof window !== 'undefined' && window.location.pathname !== '/admin/partner/signup',
  );

  return (
    <FormLayout
      header={
        isNotRootPath ? (
          <FormChapterInformation
            formChapterIds={formChapterIds}
            i18nBasePath={i18nBasePath}
          />
        ) : null
      }
      formControl={
        <>
          {isNotRootPath ? (
            <>
              {isFormValid && matchLastChapter?.path ? (
                <div className='text-center'>
                  <FormControl isValid={isFormValid} /* hasReset */ />
                </div>
              ) : null}
              <div className="p-2 flex">
                <div>
                {localStorage.Organization ? (
                  <ButtonTw
                    // disabled={currentDocumentCount >= organisationProductLimit}
                    onClick={() => navigate('/admin/system/organisation')}
                    size={'lg'}
                    rounded
                    variant={'outline'}
                  >Cancel</ButtonTw>

                ) : null}
                </div>
                <div className="w-1/2"> 
              
                  {matchSecondChapter?.path ? (
                    <FormLinkButton
                      label={<Localized dictKey={`${i18nPath}.action.navigate back.label`} />}
                      to={firstChapterPath}
                      triggerProperties={firstChapterTriggerProperties}
                      variant='accent'
                      className="ml-6"
                    />
                  ) : null}
                  {matchLastChapter?.path ? (
                    <FormLinkButton
                      label={<Localized dictKey={`${i18nPath}.action.navigate back.label`} />}
                      to={secondChapterPath}
                      triggerProperties={lastChapterTriggerProperties}
                      variant='accent'
                      className="ml-6"
                    />
                  ) : null}
                </div>
                <div className="w-1/2 flex justify-end">
                  {matchFirstChapter?.path ? (
                    <FormLinkButton
                      label={<Localized dictKey={`${i18nPath}.action.navigate next.label`} />}
                      to={secondChapterPath}
                      triggerProperties={firstChapterTriggerProperties}
                      variant='accent'
                      className="p-2"
                    />
                  ) : null}
                  {matchSecondChapter?.path ? (
                    <FormLinkButton
                      label={<Localized dictKey={`${i18nPath}.action.navigate next.label`} />}
                      to={lastChapterPath}
                      triggerProperties={secondChapterTriggerProperties}
                      variant='accent'
                    />
                  ) : null}

                </div>
              </div>

            </>

          ) : null}

        </>
      }
      renderErrors={isNotRootPath ? <FormErrors i18nBasePath={i18nBasePath} /> : null}
    >
      <Router style={{ width: '100%' }}>
        <PrivateRoute<OrganisationSignupAdditionalInformationFragmentProps>
          path={`${OrganisationSignupDocumentFormChapter.ADDITIONAL_INFORMATION}`}
          component={OrganisationSignupAdditionalInformationFragment}
          passProps={{
            i18nBasePath,
            name: 'additionalInformation',
          }}
        />
        <PrivateRoute<OrganisationSignupBaseInformationFragmentProps>
          path={`${OrganisationSignupDocumentFormChapter.BASE_INFORMATION}`}
          component={OrganisationSignupBaseInformationFragment}
          passProps={{
            i18nBasePath,
            name: 'organisation',
          }}
        />
        <PrivateRoute<OrganisationSignupOwnerFragmentProps>
          path={`${OrganisationSignupDocumentFormChapter.OWNER_INFORMATION}`}
          component={OrganisationSignupOwnerFragment}
          passProps={{
            i18nBasePath,
            name: 'owner',
          }}
        />
        <PrivateRoute path={'/'} component={OrganisationSignupFormInitialScreen} />
      </Router>
      {children}
    </FormLayout>
  );
};
