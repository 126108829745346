import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { Container, Paper } from 'src/app/components';
import { useTranslation } from 'react-i18next';

export const OrganisationTermsPage = () => {
  const { t } = useTranslation();
  const termsOfBusiness = t('organisation:/organisation/terms.markdown', {});
  return (
    <div className='md:max-w-4xl mx-auto'>
      <div className='py-8 px-4 content'>
        <ReactMarkdown>{termsOfBusiness}</ReactMarkdown>
      </div>
    </div>
  );
};
