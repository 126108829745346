import * as React from 'react';
import * as yup from 'yup';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { TextField } from '@material-ui/core';
import { Button, DropDown, Grid, LoadingIndicator, Typography } from 'src/app/components';
import { useAppContext } from 'src/app/context';
import { NotificationType } from 'src/app/constants';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';
import { useAppStaticQueryConfig } from 'src/app/hooks';

export interface OrganisationUserInvitationReqBody {
  inviteEmail: string;
  organisationId: string;
  role: OrganisationRole;
  // issuer  - can bet gotten via user.auth
}

enum OrganisationRole {
  OWNER = 'owner',
  USER = 'user',
  MANAGER = 'manager',
}

const userRoles: OrganisationRole[] = [
  OrganisationRole.MANAGER,
  OrganisationRole.USER,
];

const emailSchema = yup.string().email();

const InviteUser = () => {
  const {createNotification, currentOrganisation, firebase} = useAppContext();
  
  const isEmailValid = (val: string) => emailSchema.isValidSync(val)


  const [email, setEmail] = React.useState<string>('');
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<OrganisationRole | null>(null);
  const { env } = useAppStaticQueryConfig();

  const onSubmit = async () => {
    if(!firebase || !currentOrganisation?.id || !email || !role) {
      return;
    }
    try {
      setIsRequestingUpdate(true);
      // send user invitation email
      const inviteOrganisationUser = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'organisationFunction-organisationUserInvitation',
      );

      const params: OrganisationUserInvitationReqBody = {
        inviteEmail: email,
        organisationId: currentOrganisation.id,
        role,
      };

      await inviteOrganisationUser(params);
      createNotification(NotificationType.SUCCESS, `Invitation email is sent to ${email}`);
      setEmail('');
      setRole(null);
    } catch (e) {
      console.warn('Error: ', e);
      createNotification(NotificationType.ERROR, `Error: ${e?.message || ''}`);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  const onChangeUserRole = (idx: number) => {
    if(!Number.isNaN(idx)) {
      setRole(userRoles[idx] as OrganisationRole);
    } else {
      setRole(null);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'example@example.com'}
          label={'Invitation email'}
          fullWidth
          variant={'outlined'}
          style={{ height: '100%' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropDown
          disabled={!email || !isEmailValid(email)}
          // themeMode={themeMode || ThemeMode.LIGHT} // TODO: Darkmode broken
          className='min-h-[56px]'
          variant={'contained'}
          // color={isFilterOn() ? 'secondary' : 'primary'}
          label={
            <Typography align={'center'} >
                { role ? role : 'Select a role' }
            </Typography>
          }
          placement={'top'}
          options={[
            ...userRoles.map((role) => (
            <Typography
              
              // color={!isFilterOnId(categoryId) ? 'primary' : 'secondary'}
              align={'center'}
              renderAs={'span'}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {role}
            </Typography>
            )),
          ]}
          // selectedIndex={selectedIndex !== null ? selectedIndex : -1}
          onChange={(idx: number) => onChangeUserRole(idx)}
          showArrowIcon={false}
          openOnHover
          fullWidth
        />
        </Grid>
      <Grid item xs={12} md={12}>
        { isRequestingUpdate ? <LoadingIndicator /> :
        <Button disabled={!email || !isEmailValid(email) || !role} fullWidth color={'primary'} style={{ height: '100%' }}>
          <Typography onClick={onSubmit} >
            Send email invitation
          </Typography>
        </Button> }
      </Grid>
    </Grid>
  );
};

export { InviteUser };
