import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { navigate } from 'gatsby';

import {
  DebugObject,
  Divider,
  Grid,
  StringPropertyRow,
  Typography,
  Button,
  Toolbar,
} from 'src/app/components';
import { onOpenInNewWindow } from 'src/app/lib';

import { DaytimeEventDocument } from 'src/app/types';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';
import { NotificationType } from 'src/app/constants';
// import { useAppContext } from 'src/app/hooks';

export interface ProductEventViewProps {
  productEvent: DaytimeEventDocument;
}

interface DaytimeEventCancelFunctionParams {
  organisationId: string;
  productId: string;
  eventId: string;
  // notificationType: OrganisationEmailNotificationType;
  // userId: string; // user id to add to the pool
}

export const ProductEventView = ({ productEvent }: ProductEventViewProps) => {
  const { currentOrganisation, firebase,createNotification } = useAppContext();

  const { id, productId, status } = productEvent;
  const { env } = useAppStaticQueryConfig();
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  const onCancelEvent = async () => {
    if (!firebase || !id || !productId || !currentOrganisation?.id) {
      return;
    }
    try {
      setIsRequestingUpdate(true);
      const daytimeEventCancel = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'organisationFunction-daytimeEventCancel',
      );
      const params: DaytimeEventCancelFunctionParams = {
        organisationId: currentOrganisation.id,
        productId,
        eventId: id,
      };
      await daytimeEventCancel(params);
      createNotification(
        NotificationType.SUCCESS,
        `The event has been cancelled`,
        5000,
      );
    } catch (err) {
      console.log('err: ', err);
    } finally {
      
      setIsRequestingUpdate(false);
    }
  };

  if (!productEvent) {
    return null;
  }

  const isDebug = false;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <StringPropertyRow title={'Event date id:'} value={id || 'N/A'} />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow
          title={'Orders'}
          value={productEvent.orders?.length?.toString() || 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow
          title={'Orders off system'}
          value={productEvent.offSystemOrderCount?.toString() || 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color={'primary'} onClick={() => navigate('bookings')}>
          <Typography>Booking management</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color={'primary'} onClick={() => navigate('private-request')}>
          <Typography>Private group requests</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Button fullWidth color={'primary'} disabled>
          <Typography>Contact participants</Typography>
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          color={'primary'}
          onClick={() =>
            onOpenInNewWindow(
              `/admin/partner/communication/message_system?product=${encodeURIComponent(
                `${productId}:${id}`,
              )}`,
            )
          }
        >
          <Typography>Contact support</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color={'secondary'} disabled>
          <Typography>Change event parameters</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={isRequestingUpdate || status === 'cancelled'}
          fullWidth
          color={'secondary'}
          onClick={() => onCancelEvent()}
        >
          <Typography>
            {status === 'cancelled' ? 'Event is cancelled' : 'Cancel event'}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DebugObject isDebug={isDebug} object={productEvent || {}} />
      </Grid>
    </Grid>
  );
};
function createStackedNotification(SUCCESS: any, arg1: string) {
  throw new Error('Function not implemented.');
}

