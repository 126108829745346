import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { createFormFragmentName } from 'src/app.system/components/document/baseDocumentFormFragments/lib';
import MUICheckbox, {
  CheckboxProps as MUICheckboxProps,
} from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  // DocumentStringFormFragment,
  DocumentSelectFormFragment,
} from 'src/app.system/components';

import {
  ArrayField,
  TextField,
  NumberField,
  integerTransformWithoutZero,
  DateField,
} from 'src/app/components/RHF/Fields';

import { Grid, Link, LoadingIndicator, Localized, Typography } from 'src/app/components';

import { FormFragmentContainer, composeFieldName } from 'src/app/components/RHF';
import { OrganisationTurnoverAmount, SocialMediaType } from 'src/app/constants';
// import { CategoryTypeDocumentData } from 'src/app/types';
import { useStyles, useLocalizationContext, useAppContext } from 'src/app/context';
import {
  useSourcedCategories,
  useSourcedCategoryTypes,
  useSourcedLocales,
} from 'src/app/hooks';
import { SocialMediaInformation } from 'src/admin/types';

export interface OrganisationSignupAdditionalInformationFragmentProps {
  i18nBasePath: string;
  name?: string;
}

export const OrganisationSignupAdditionalInformationFragment = ({
  i18nBasePath,
  name,
}: OrganisationSignupAdditionalInformationFragmentProps) => {
  const { t } = useTranslation();
  const categories = useSourcedCategories();
  const categoryTypes = useSourcedCategoryTypes();
  const locales = useSourcedLocales();
  const { watch, setValue } = useFormContext();
  const styles = useStyles();
  const { currencyId } = useAppContext();

  const turnoverOptions = [
    { id: '---' },
    { id: `${OrganisationTurnoverAmount.LESS_THAN_FIFTY} ${currencyId}` },
    { id: `${OrganisationTurnoverAmount.BETWEEN_FIFTY_AND_ONE_HUNDRED} ${currencyId}` },
    {
      id: `${OrganisationTurnoverAmount.BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED} ${currencyId}`,
    },
    {
      id: `${OrganisationTurnoverAmount.BETWEEN_THREE_HUNDRED_AND_FIVE_HUNDRED} ${currencyId}`,
    },
    {
      id: `${OrganisationTurnoverAmount.BETWEEN_FIVE_HUNDRED_AND_MILLION} ${currencyId}`,
    },
    { id: `${OrganisationTurnoverAmount.ABOVE_MILLION} ${currencyId}` },
  ];

  const [categoryTypeIdsState, setCategoryTypeIdsState] = React.useState<string[]>([]);

  // TODO: try using composeFieldName instead...

  const organisationYearlyGrossTurnoverFieldName = composeFieldName(
    'organisationYearlyGrossTurnover',
    name,
  );

  const watchedYearlyGrossTurnover: { id: OrganisationTurnoverAmount }[] = watch(
    organisationYearlyGrossTurnoverFieldName,
    [],
  );

  const organisationCategoryTypeFieldName = composeFieldName(
    'organisationCategoryTypeIds',
    name,
  );

  // const organisationCategoryTypeFieldName = createFormFragmentName(
  //   'organisationCategoryTypeIds',
  //   'organisationCategoryTypeIds',
  //   name,
  // );

  const watchedOrganisationCategoryTypeIds: string[] = watch(
    organisationCategoryTypeFieldName,
    [],
  );

  const organisationCategoriesFieldName = composeFieldName(
    'organisationCategoryIds',
    name,
  );

  // const organisationCategoriesFieldName = createFormFragmentName(
  //   'organisationCategoryIds',
  //   'organisationCategoryIds',
  //   name,
  // );

  const watchedOrganisationCategoryIds: string[] = watch(
    organisationCategoriesFieldName,
    [],
  );

  const localeIdFieldName = composeFieldName('localeId', name);

  // const localeIdFieldName = createFormFragmentName('localeId', name, '');

  const watchedLocaleId: { id: string } = watch(localeIdFieldName, '');

  const watchedHasReadTermsForOrganisations: boolean = watch(
    'hasReadTermsForOrganisations',
    false,
  );

  React.useEffect(() => {
    if (
      watchedOrganisationCategoryTypeIds?.length !== categoryTypeIdsState.length &&
      JSON.stringify(watchedOrganisationCategoryTypeIds) !==
        JSON.stringify(categoryTypeIdsState)
    ) {
      setCategoryTypeIdsState(watchedOrganisationCategoryTypeIds);
      setValue(organisationCategoriesFieldName, []);
    }
  }, [
    watchedOrganisationCategoryTypeIds,
    categoryTypeIdsState,
    organisationCategoriesFieldName,
  ]);

  if (!categories?.length || !categoryTypes?.length || !locales?.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      </Grid>
    );
  }

  const filteredCategoriesByCategoryType = categories.filter((cat) => {
    let isInSelectedCategoryTypes = false;
    for (
      let catTypeIdx = 0;
      catTypeIdx < watchedOrganisationCategoryTypeIds.length;
      catTypeIdx++
    ) {
      if (
        cat.meta?.categoryTypeIds.includes(watchedOrganisationCategoryTypeIds[catTypeIdx])
      ) {
        isInSelectedCategoryTypes = true;
      }
    }
    return isInSelectedCategoryTypes;
  });

  const featureEnabled = true;

  return (
    <FormFragmentContainer>
      <TextField
        name={'website'}
        namePrefix={name}
        i18nKey={`${name}.website`}
        i18nPath={i18nBasePath}
      />
      {/* <TextField
        name={'socialMedia'}
        namePrefix={name}
        i18nKey={`${name}.socialMedia`}
        i18nPath={i18nBasePath}
      /> */}
      {featureEnabled ? (
        <ArrayField
          name={'socialMedia'}
          namePrefix={name}
          i18nKey={`${name}.socialMedia`}
          i18nPath={i18nBasePath}
          // i18nKey={'foodOptions'}
          // i18nPath={arrayI18nPrefix}
          // name={'foodOptions'}
          // namePrefix={arrayNamePrefix}
          initialItemValue={
            {
              type: SocialMediaType.YOUTUBE,
              value: '',
            } as SocialMediaInformation
          }
          initialItems={[]}
          renderElements={(renderProps: FormArrayFieldRenderProps) => {
            const {
              defaultValues: arrayDefaultValues,
              namePrefix: arrayNamePrefix,
              i18nPrefix: arrayI18nPrefix,
              index: arrayItemIndex,
              // arrayItems: arrayItems2,
            } = renderProps;
            // FIXME: needs to be validated per submitted URL in FE + BE im submitted url starts with a matching url of the mapped type
            return (
              <>
                <DocumentSelectFormFragment
                  i18nKey={'type'}
                  i18nPath={arrayI18nPrefix}
                  name={'type'}
                  namePrefix={arrayNamePrefix}
                  defaultValue={arrayDefaultValues?.type || ''}
                  selectData={[
                    { id: SocialMediaType.INSTAGRAM },
                    { id: SocialMediaType.YOUTUBE },
                  ]}
                  selectDataPropertyName={'id'}
                  hasNoNoneOption
                />
                <TextField
                  i18nKey={'value'}
                  i18nPath={arrayI18nPrefix}
                  name={'value'}
                  namePrefix={arrayNamePrefix}
                  defaultValue={arrayDefaultValues?.value || ''}
                />
              </>
            );
          }}
        >
          {/* <FieldErrors i18nKey={i18nKey} name={name || ''} /> */}
          {/* <FieldAdditionalContainer /> */}
        </ArrayField>
      ) : null}
      <NumberField
        name={'organisationEmployeeCount'}
        namePrefix={name}
        i18nKey={`${name}.organisationEmployeeCount`}
        i18nPath={i18nBasePath}
        defaultValue={1}
        isInteger
        transform={integerTransformWithoutZero}
        // defaultValue={getArrayDefaultValue(arrayItems, arrayItemIndex, 'timeDelta')}
      />
      <DateField
        name={'organisationFoundedDate'}
        namePrefix={name}
        i18nKey={`${name}.organisationFoundedDate`}
        i18nPath={i18nBasePath}
        defaultValue={new Date()}
        datePickerMode={'DATE'}
        disableFuture
        // TODO: yearwise navigation needed
        // value is string or isnt submitted at all...
      />
      <DocumentSelectFormFragment
        i18nPath={i18nBasePath}
        i18nKey={organisationYearlyGrossTurnoverFieldName}
        name={organisationYearlyGrossTurnoverFieldName}
        selectData={turnoverOptions || []}
        selectDataPropertyName={'id'}
        hasNoNoneOption
        // defaultValue={''}
        defaultValue={watchedYearlyGrossTurnover || ''}
      />
      <DocumentSelectFormFragment
        i18nPath={i18nBasePath}
        i18nKey={`${name}.organisationCategoryTypeIds`}
        name={organisationCategoryTypeFieldName}
        selectData={categoryTypes || []}
        selectDataPropertyName={'id'}
        multiple
        hasNoNoneOption
        // defaultValue={''}
        defaultValue={watchedOrganisationCategoryTypeIds || ''}
        // onBlur={// TODO reset organisationCategoryIds to empty array}
      />
      {watchedOrganisationCategoryTypeIds?.length ? (
        <>
          {/** //TODO multistep select for category logic */}
          <DocumentSelectFormFragment
            i18nPath={i18nBasePath}
            i18nKey={`${name}.organisationCategoryIds`}
            name={organisationCategoriesFieldName}
            selectData={filteredCategoriesByCategoryType || []}
            selectDataPropertyName={'id'}
            multiple
            hasNoNoneOption
            // defaultValue={''}
            defaultValue={watchedOrganisationCategoryIds || ''}
          />
        </>
      ) : null}
      <DocumentSelectFormFragment
        i18nPath={i18nBasePath}
        i18nKey={`${name}.localeId`}
        name={`${name}.localeId`}
        selectData={locales || []}
        selectDataPropertyName={'id'}
        hasNoNoneOption
        // defaultValue={''}
        defaultValue={watchedLocaleId || ''}
      />
      {/* <DocumentStringFormFragment
        fieldId={'socialMediaTODO'}
        i18nBasePath={i18nBasePath}
      /> */}

      <FormGroup row className={styles.px__4}>
        <FormControlLabel
          control={
            <MUICheckbox
              checked={watchedHasReadTermsForOrganisations}
              onChange={(e) =>
                setValue('hasReadTermsForOrganisations', e.target.checked)
              }
              color='primary'
              name='read-terms-for-supplier'
            />
          }
          label={
            <Typography align={'center'}>
              <span className='mr-2'>
                <Localized
                  dictKey={
                    'organisation:document.organisationSignupDocument.action.confirm-terms-organisation.checkbox-label'
                  }
                />
              </span>
              <Link
                href={
                  t(
                    'organisation:document.organisationSignupDocument.action.confirm-terms-organisation.checkbox-link.url',
                  ) || ''
                }
                align={'center'}
              >
                <Localized
                  dictKey={
                    'organisation:document.organisationSignupDocument.action.confirm-terms-organisation.checkbox-link.label'
                  }
                />
              </Link>
            </Typography>
          }
        />
      </FormGroup>

    </FormFragmentContainer>
  );
};
