import * as React from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';

import { useStyles } from 'src/app/context';
import { LocalStorageKey, NotificationType, SignupType } from 'src/app/constants';

import { Grid, LoadingIndicator, Localized, PrivateRoute } from 'src/app/components';
import {
  useAppContext,
  useAppStaticQueryConfig,
  useLocalizationContext,
  // useLocalizedNavigation,
} from 'src/app/hooks';
import { FormRootContainer } from 'src/app/components/RHF';
import {
  OrganisationSignupDocumentData,
  OrganisationSignupFormData,
} from 'src/app.organisation/types';

// LOCAL IMPORTS
import { SignupFormData } from 'src/app/types';
import { userSignup } from 'src/api/callables';
import { SupplierRegistrationConfirmation } from 'src/app.consumer/components/notification/SupplierRegistrationConfirmation';
import {
  OrganisationSignupForm,
  OrganisationSignupFormProps,
} from './OrganisationSignupForm';

// import { isSourceDocumentDebugModeOn, useSubmitOrganisationDocument } from '../lib';

import {
  defaultValues,
  defaultValues__DEV,
  createDocumentResolver,
} from './organisationSignupDocument.forms.config';
import { organisation } from 'src/locales/de';
import { Timestamp, doc, getFirestore, setDoc } from 'firebase/firestore';
import { BaseOrganisationDocument } from 'src/admin/types';

// import { FormChapterControls } from './FormChapterControls'; // TODO: remove and use same mechanic as hee in products?

const documentI18nBasePath = 'organisation:document.organisationSignupDocument';
// const screenI18nBasePath = 'organisation:org-products';
const routeBasepath = 'admin/partner/signup';
type DocumentType = OrganisationSignupDocumentData;
// type DocumentType = OrganisationSignupFormData;

// const localizationPath = 'shop:signupForm';

const CreateDocumentScreen = () => {
  const {
    createNotification,
    createStackedNotification,
    firebase,
    isAppDebug,
    isLoggedIn,
  } = useAppContext();
  const { env } = useAppStaticQueryConfig();
  const { language } = useLocalizationContext();
  // const { navigateLocalized } = useLocalizedNavigation();
  const styles = useStyles();

  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);
  const [hasSubmitSuccess, setHasSubmitSuccess] = React.useState<boolean>(false);
  let defaultOrg=isAppDebug ? defaultValues__DEV : defaultValues;
  let currentLocalStorageOrganization=[];
  if(localStorage.Organization){
     currentLocalStorageOrganization = JSON.parse(
      localStorage.Organization
    );
    defaultOrg={
      additionalInformation: {
        localeId: currentLocalStorageOrganization.organisation.localeId,
        organisationEmployeeCount: currentLocalStorageOrganization.organisation.organisationEmployeeCount,
        organisationFoundedDate: currentLocalStorageOrganization.organisation.organisationFoundedDate,
        organisationYearlyGrossTurnover:currentLocalStorageOrganization.organisation.organisationYearlyGrossTurnover,
        organisationCategoryTypeIds:currentLocalStorageOrganization.organisation.organisationCategoryTypeIds,
        organisationCategoryIds: currentLocalStorageOrganization.organisation.organisationCategoryIds,
        socialMedia: currentLocalStorageOrganization.organisation.socialMedia,
        website: currentLocalStorageOrganization.organisation.website,
      },
      organisation: currentLocalStorageOrganization.organisation,
      owner: currentLocalStorageOrganization.organisationOwner,
      hasReadTermsForOrganisations: true,
      id:currentLocalStorageOrganization.id,
    }
    console.log(currentLocalStorageOrganization,'cvcx');
    console.log(defaultValues__DEV,'cvcx');
  }
  if (typeof window === 'undefined' /* || isLoggedIn */) {
    return null;
  }

  const onSubmit = async (orgSignupData: DocumentType) => {
    try {
      if (!firebase) {
        return;
      }

      setRequestingUpdate(true);

      // const registerOrganisation = httpsCallable(
      //   getFunctions(firebase),
      //   'organisationFunction-organisationRegistration',
      // );

      // await registerOrganisation({
      //   ...data,
      //   localeId: language || 'en',
      // });

      window.localStorage.setItem(
        LocalStorageKey.EMAIL_LOGIN,
        orgSignupData.owner.ownerEmail,
      );

      const {
        ownerEmail,
        ownerFirstName,
        ownerLastName,
        ownerPassword /* , ownerPhone also available => validate else fb will complain */,
      } = orgSignupData.owner;

      const userSignupData: SignupFormData = {
        email: ownerEmail,
        displayName: '',
        firstName: ownerFirstName || '',
        gender: '', // TODO: needs to be asked
        lastName: ownerLastName || '',
        password: ownerPassword,
        passwordRepeat: ownerPassword,
        // phoneNumber: string; // TODO: isn't asked in FE
        title: '',
        signupType: SignupType.ORGANISATION,
        localeId: language || 'en',
      };
      const signupData: OrganisationSignupFormData = {
        ...orgSignupData,
        ...userSignupData,
      };
      if(orgSignupData.id){
        const organisationDocData: any = {
          customPayoutIntervals: [],
          email: currentLocalStorageOrganization.email,
          emailConfirmedAt: currentLocalStorageOrganization.emailConfirmedAt,
          id: currentLocalStorageOrganization.id,
  
          // org needs to be unlocked first
          isOrganisationUnlocked: currentLocalStorageOrganization.isOrganisationUnlocked,
          // org operations can be disabled
          isOrganisationDisabled: currentLocalStorageOrganization.isOrganisationDisabled,
          // are all organisation documents uploaded and validated by system admin
          isOrganisationDocumentsValid: currentLocalStorageOrganization.isOrganisationDocumentsValid,
          // are all necessary fields set in payout settings
          isOrganisationPaymentDataSubmitted: currentLocalStorageOrganization.isOrganisationPaymentDataSubmitted,
          // are all necessary fields in payout settings been checked by system user
          isOrganisationPaymentDataValid: currentLocalStorageOrganization.isOrganisationPaymentDataValid,
  
          // TODO: create proper meta + check where might be necessary to use / update places where old format is used
  
          // TODO: do org initialization with default values
          // (pre config by activityType /orgType etc?) or default system based config
          meta: {
            createdAt: currentLocalStorageOrganization.meta.createdAt,
            systemIds: currentLocalStorageOrganization.meta.systemIds, // do some setup based on "holiday" config
            // v: '1',
          },
  
          notificationSettings: {
            cancelMessage: currentLocalStorageOrganization.notificationSettings.cancelMessage, // string ids of organisation/user doc item
            generalMessage: currentLocalStorageOrganization.notificationSettings.generalMessage, // string ids of organisation/user doc item
            orderMessage: currentLocalStorageOrganization.notificationSettings.orderMessage, // string ids of organisation/user doc item
            privateReservation: currentLocalStorageOrganization.notificationSettings.privateReservation, // string ids of organisation/user doc item
            referenceMessage: currentLocalStorageOrganization.notificationSettings.referenceMessage, // string ids of organisation/user doc item
            systemMessage:currentLocalStorageOrganization.notificationSettings.systemMessage, // string ids of organisation/user doc item
          },
          // TODO: update this when new user join and are on the settingslist, created out of org/user docs
  
          organisation: {
            ...orgSignupData.organisation,
            ...orgSignupData.additionalInformation,
           
            organisationFoundedDate:new Date(orgSignupData.organisation.organisationFoundedDate).toISOString().slice(0, 10)
          } as any ,
  
          organisationOwner: orgSignupData.owner,
  
          organisationData: {
            products: {
              // COUNTS:
              productsCreatedCount: currentLocalStorageOrganization.organisationData.products.productsCreatedCount,
              productsDeletedCount: currentLocalStorageOrganization.organisationData.products.productsDeletedCount,
              productsPublishedCount: currentLocalStorageOrganization.organisationData.products.productsPublishedCount,
  
              productsSoldOverall: currentLocalStorageOrganization.organisationData.products.productsSoldOverall,
              productsSoldCurrentMonth:currentLocalStorageOrganization.organisationData.products.productsSoldCurrentMonth,
              productsSoldToday: currentLocalStorageOrganization.organisationData.products.productsSoldToday,
  
              // ANALYTICS:
              // productSoldMost - productId + count
              // TODO: lookup planning in github
            },
            user: {
              userCount: currentLocalStorageOrganization.organisationData.user.userCount,
              users:currentLocalStorageOrganization.organisationData.user.users,
              // userMappingList - with DisplayNames for single lookup call?
            },
          },
          // TODO: structure access by user type
          organisationSettings: {
  
            // payoutFrequency
            // notificationRecipients
          },
          // TODO: these may depend on the type of categoryType ids a org owner has entered when signed up
          organisationUploadDocuments: {
            // NOTE: it would be better that each document would be validated separately by sys user => extra flag
            organisationInsuranceDocument: currentLocalStorageOrganization.organisationUploadDocuments.organisationInsuranceDocument,
            organisationRegistrationDocument: currentLocalStorageOrganization.organisationUploadDocuments.organisationRegistrationDocument,
            organisationLicenceDocument: currentLocalStorageOrganization.organisationUploadDocuments.organisationLicenceDocument,
          },
          paymentSettings: {
            bank: {
              bankName: currentLocalStorageOrganization.paymentSettings.bank.bankName,
              bankAddressStreet: currentLocalStorageOrganization.paymentSettings.bank.bankAddressStreet,
              bankAddressStreetNumber: currentLocalStorageOrganization.paymentSettings.bank.bankAddressStreetNumber,
              bankAddressZip: currentLocalStorageOrganization.paymentSettings.bank.bankAddressZip,
              bankAddressCity:  currentLocalStorageOrganization.paymentSettings.bank.bankAddressCity,
              bankAddressCountry:currentLocalStorageOrganization.paymentSettings.bank.bankAddressCountry,
              iban: currentLocalStorageOrganization.paymentSettings.bank.iban,
              bic: currentLocalStorageOrganization.paymentSettings.bank.bic,
            },
            payoutBaseDate: Timestamp.fromDate(new Date('2000-01-01T00:00:00.000+01:00')),
            payoutInterval: currentLocalStorageOrganization.paymentSettings.payoutInterval,
          },
          // NOTE: currently any new customer gets the activity type?
          // productCategoryTypeConfig: [{
          //   id: ProductType.ACTIVITY,
          //   isEnabled: true,
          //   productLimit: 16,
          // }],
          productCategoryTypeConfig: orgSignupData.additionalInformation.organisationCategoryIds.map((id) => {return {id, isEnabled:true, productLimit:16} as any}),
        };
    console.log('sadhajs',organisationDocData)
        const docRef = doc(getFirestore(firebase),`organisation/${orgSignupData.id}`);
        await setDoc(docRef, organisationDocData);
        localStorage.removeItem('Organization');
        await navigate('/admin/system/organisation');
        // const organisationDocRef = firebase.firestore().collection('organisation').doc(currentLocalStorageOrganization.id);
        // awaitorganisationDocRef.update({ ...userDoc.data(), ...signupDataRest });

      }else{
      const res = await userSignup(firebase, env, signupData);
      if (res) {
        // NOTE: has ERROR
        if (res.details?.codePrefix === 'auth' && res.details?.errorInfo) {
          createStackedNotification(
            NotificationType.ERROR,
            <Localized
              dictKey={`${documentI18nBasePath}.action.signup-submit.notification.error`}
              translationOptions={{
                errorNs: 'firebase',
                errorCode: res.details.errorInfo.code,
              }}
            />,
          );
        } else {
          createStackedNotification(
            NotificationType.ERROR,
            <Localized
              dictKey={`${documentI18nBasePath}.action.signup-submit.notification.error`}
              translationOptions={{ errorNs: 'internal', errorCode: 'unknown' }}
            />,
          );
        }
      } else {
        createNotification(
          NotificationType.INFO,
          <SupplierRegistrationConfirmation orgSignupData={signupData} />,
          9999999,
        );
        setHasSubmitSuccess(true);
        // navigate('/admin/partner');
        await navigate('/');
      }

      }
    
    } catch (err) {
      console.log(err);
      createStackedNotification(
        NotificationType.ERROR,
        <Localized
          dictKey={`${documentI18nBasePath}.action.signup-submit.notification.error`}
          translationOptions={{ errorNs: 'internal', errorCode: 'unknown' }}
        />,
      );
    } finally {
      setRequestingUpdate(false);
    }
  };

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    const data: DocumentType = {
      ...dataRaw,
    };

    // TODO: handle password => create account logic
    // TODO: maybe create account first then send organisation confirmation

    await onSubmit(data);
  };

  return (
    <FormRootContainer<DocumentType>
      defaultValues={defaultOrg}
      resolver={createDocumentResolver}
      onSubmit={onSubmitWrapper}
      useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
      isDebug={isAppDebug}
      renderForm={({ formProps }) => {
        return isRequestingUpdate ? (
          <LoadingIndicator />
        ) : (
          <Router basepath={routeBasepath} style={{ width: '100%' }}>
            <PrivateRoute<OrganisationSignupFormProps<DocumentType>>
              path={'/*'}
              component={OrganisationSignupForm}
              passProps={{
                formProps,
                i18nBasePath: documentI18nBasePath,
                routeBasepath,
              }}
            />
          </Router>
        );
      }}
    />
  );
};

export const OrganisationSignupFormRouter = () => {
  return (
    <>
      <Router>
        <PrivateRoute<{}> path={'/*'} component={CreateDocumentScreen} />
      </Router>
    </>
  );
};
