import * as React from 'react';

import { Grid, LocalizedErrorIndicator, LoadingIndicator } from 'src/app/components';
import { DaytimeEventDocument } from 'src/app/types';
import { FirestoreDocData } from 'src/app/container';

import { ProductEventView } from './ProductEventView';

export interface ProductEventContainerProps {
  eventId?: string; // ReachRouter prop
  productId: string;
}

export const ProductEventContainer = ({
  eventId,
  productId,
}: ProductEventContainerProps) => {
  if (!productId || !eventId) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <FirestoreDocData<DaytimeEventDocument>
          useSubscription
          documentPath={`product/${productId}/daytimeEvent/${eventId}`}
          renderElements={({ data, status }) => {
            if (status === 'loading') {
              return <LoadingIndicator />;
            }
            if (status === 'error') {
              return (
                <LocalizedErrorIndicator
                  i18nContext={'organisation'}
                  i18nErrorCode={'LOADING_COLLECTION'}
                  i18nErrorAreaCode={'product/daytimeEvent'}
                />
              );
            }
            return status !== 'success' || !data?.id ? null : (
              <ProductEventView productEvent={data} />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
