import * as React from 'react';
import { Router } from '@reach/router';
import SourceDataCityIcon from '@material-ui/icons/LocationCity';

import { getOrganisationSourceCollectionPath, createDocumentId } from 'src/lib/db';

import {
  SourceCollectionName,
  ProductDocumentState,
  ProductCategoryType,
} from 'src/app/constants';
import { PrivateRoute } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

import { FormRootContainer, FormLayout } from 'src/app/components/RHF';
import { ProductDocumentData } from 'src/app/types';

import { OrganisationAdminRootScreen } from 'src/app.organisation/constants';
import {
  categoryTypeRouteMappings,
  hasOrganisationProductType,
} from 'src/app.organisation/lib';

// LOCAL IMPORTS
import { useAdminContext } from 'src/admin/context';
import { BaseOrganisationDocument } from 'src/admin/types';
import { useSubmitOrganisationDocument } from 'src/app.organisation/hooks';
import {
  ProductDocumentForm,
  ProductDocumentFormProps,
} from '../../../../components/organisationAdminApp/data/productData/ProductDocumentForm';

import {
  defaultValues,
  defaultValues__DEV,
  createDocumentResolver,
} from '../../../../components/organisationAdminApp/data/productData/productDocument.forms.config';

import { FormChapterNavigation } from '../../../../components/organisationAdminApp/data/productData/FormChapterNavigation';

const documentI18nBasePath = 'organisation:document.activityProductDocument';
const screenI18nBasePath = 'organisation:org-products';
const collectionName = SourceCollectionName.PRODUCT;
const collectionPath = (orgId: string) =>
  getOrganisationSourceCollectionPath(orgId, collectionName);
// const routeBasepath = `/admin/partner/${collectionName}`;
const routeBasepath = `/admin/partner/${'product'}`;

type DocumentType = ProductDocumentData;

const MenuIcon = () => <SourceDataCityIcon />;
const screenId = OrganisationAdminRootScreen.products;

const defaultValues__DEV_fix = {
  ...defaultValues__DEV,
  meta: {
    ...defaultValues__DEV.meta,
    categoryIds: defaultValues__DEV.meta.categoryIds[0],
  },
};

export const CreateScreen = () => {
  const { currentOrganisation, isAppDebug, user } = useAppContext();
  const { document } = useAdminContext();

  const orgDocument = document as unknown as BaseOrganisationDocument;
  // const categories = useSourcedCategories(); // CategoryTypes
  const { onSubmit } = useSubmitOrganisationDocument<DocumentType>();

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    console.log('onSubmit created document => dataRaw: ', dataRaw);
    // console.log('current org: ', currentOrganisation);
    // test

    if (!currentOrganisation?.id) {
      return null;
    }

    const id = createDocumentId(); // NOTE: creating random id fro product
    const data: DocumentType = {
      ...dataRaw,
      // id: createCleanDocumentId(dataRaw.id),
      id,
      meta: {
        ...dataRaw.meta,
        id,
        status: ProductDocumentState.DRAFT,
        // test commit
      },
    };

    // product can't have multiple categories but it's defined that way in spec so we change string to array of string
    if (!Array.isArray(data.meta.categoryIds)) {
      data.meta.categoryIds = [data.meta.categoryIds];
    }

    console.log('onSubmit created document => transformed data for submit: ', data);

    return onSubmit(
      collectionPath(currentOrganisation.id),
      collectionName,
      data,
      currentOrganisation.id,
    );
  };

  if (typeof window === 'undefined') {
    return null;
  }

  const hasProductType = (productType: ProductCategoryType) =>
    hasOrganisationProductType(orgDocument, productType);

  console.log('orgDocument: ', orgDocument);

  /** // TODO: optional chapters (variants?) */
  return (
    <FormRootContainer<DocumentType>
      className=''
      defaultValues={isAppDebug ? defaultValues__DEV_fix : defaultValues}
      // defaultValues={defaultValues}
      isDebug={isAppDebug}
      resolver={createDocumentResolver}
      onSubmit={onSubmitWrapper}
      renderForm={({ formProps }) => {
        const createRoutePath = `${routeBasepath}/create`;
        // console.log('createRoutePath: ', createRoutePath);
        const i18nPath = window.location.pathname.replace(createRoutePath, '') || '/';
        // console.log('i18nPath: ', i18nPath);

        // generation of i18n key
        const cat = i18nPath.split('/')[1] || '';
        // console.log('cat: ', cat);

        // const pathRest = `${i18nPath
        //   .split('/')
        //   .filter((val, idx) => idx > 1)
        //   .join('.')}`;
        // console.log('i18nPath2: ', pathRest);

        const productCategoryTypes: ProductCategoryType[] = [
          ProductCategoryType.ACTIVITY,
          ProductCategoryType.BOAT,
          // ProductCategoryType.RENTAL,
          // ProductCategoryType.RESERVATION,
          // ProductCategoryType.TRANSFER,
        ];
        return (
          // use also localStorage keep last product state for review?
          <>
            <Router basepath={createRoutePath} style={{ width: '100%' }}>
              {productCategoryTypes?.length
                ? productCategoryTypes
                    .filter((productCategoryType) => hasProductType(productCategoryType))
                    .map((productCategoryType) => (
                      <PrivateRoute<ProductDocumentFormProps<DocumentType>>
                        key={productCategoryType}
                        path={`${categoryTypeRouteMappings[productCategoryType]}/*`}
                        component={ProductDocumentForm}
                        passProps={{
                          formProps,
                          i18nBasePath: documentI18nBasePath,
                          routeBasepath: `${createRoutePath}/${categoryTypeRouteMappings[productCategoryType]}`,
                          categoryId: productCategoryType,
                        }}
                      />
                    ))
                : null}
              <PrivateRoute<{}>
                path={'/'}
                component={() => (
                  <FormLayout
                    formControl={null}
                    renderErrors={null}
                    className='pt-4'
                  >
                    <FormChapterNavigation
                      i18nBasePath={documentI18nBasePath}
                      // formChapterIds={getOrganisationCategoryTypeRoutes(orgDocument)}
                      routeBasepath={createRoutePath}
                      isRootChapter
                    />
                  </FormLayout>
                )}
              />
            </Router>
          </>
        );
      }}
    />
  );
};
