import * as React from 'react';
import { FieldValues } from 'react-hook-form';

import { Grid, Localized, Typography } from 'src/app/components';

import {
  FormErrors,
  FormControl,
  FormRootContainerProps,
  FormLayout,
  FieldStyle,
} from 'src/app/components/RHF';

import { TextField } from 'src/app/components/RHF/Fields';

// TODO: move to central place + generic
interface BaseFormProps<T extends FieldValues> {
  formProps: FormRootContainerProps<T>;
  i18nBasePath: string;
}

export interface OrganisationPayoutFormProps<T extends FieldValues>
  extends BaseFormProps<T> {
  isSubmitDataValid: boolean;
}

export const OrganisationPayoutForm = <T extends FieldValues>({
  i18nBasePath,
  isSubmitDataValid,
}: OrganisationPayoutFormProps<T>) => {
  // const isValid = false; // TODO
  return (
    <FormLayout
      header={
        <Grid item xs={12}>
          <Typography align={'center'} variant={'h4'} renderAs={'h2'}>
            <Localized dictKey={`${i18nBasePath}.title`} />
          </Typography>
        </Grid>
      }
      formControl={<FormControl isValid={isSubmitDataValid} /* hasReset */ />}
      // renderErrors={<FormErrors i18nBasePath={i18nBasePath} />}
    >
      <TextField name={'bankName'} i18nKey={'bankName'} i18nPath={i18nBasePath} />
      <TextField name={'iban'} i18nKey={'iban'} i18nPath={i18nBasePath} />
      <TextField name={'bic'} i18nKey={'bic'} i18nPath={i18nBasePath} />
      <TextField
        name={'bankAddressStreet'}
        i18nKey={'bankAddressStreet'}
        i18nPath={i18nBasePath}
      />
      <TextField
        name={'bankAddressStreetNumber'}
        i18nKey={'bankAddressStreetNumber'}
        i18nPath={i18nBasePath}
      />
      <TextField
        name={'bankAddressZip'}
        i18nKey={'bankAddressZip'}
        i18nPath={i18nBasePath}
      />
      <TextField
        name={'bankAddressCity'}
        i18nKey={'bankAddressCity'}
        i18nPath={i18nBasePath}
      />
      <TextField
        name={'bankAddressCountry'}
        i18nKey={'bankAddressCountry'}
        i18nPath={i18nBasePath}
      />
    </FormLayout>
  );
};
