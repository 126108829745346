import * as React from 'react';

import {
  DocumentStringFormFragment,
  DocumentPasswordWithConfirmationFormFragment,
} from 'src/app.system/components';

import { FormFragmentContainer, composeFieldName } from 'src/app/components/RHF';

export interface OrganisationSignupOwnerFragmentProps {
  i18nBasePath: string;
  name?: string;
}
export const OrganisationSignupOwnerFragment = ({
  i18nBasePath,
  name,
}: OrganisationSignupOwnerFragmentProps) => {
  return (
    <FormFragmentContainer>
      <DocumentStringFormFragment
        fieldId={composeFieldName('ownerEmail', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentPasswordWithConfirmationFormFragment
        fieldId={composeFieldName('ownerPassword', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('ownerPhone', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('ownerFirstName', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('ownerLastName', name)}
        i18nBasePath={i18nBasePath}
      />
      {/* <DocumentStringFormFragment
        fieldId={composeFieldName('socialMediaTODO', name)}
        i18nBasePath={i18nBasePath}
      /> */}
    </FormFragmentContainer>
  );
};
