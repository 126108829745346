import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { navigate } from 'gatsby';

import {
  DebugObject,
  Divider,
  Grid,
  StringPropertyRow,
  Typography,
  Button,
  Toolbar,
} from 'src/app/components';
import { onOpenInNewWindow, isSSR } from 'src/app/lib';

import { DaytimeEventDocument } from 'src/app/types';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

const ReactJSON = React.lazy(() => import('react-json-view'));

interface DaytimeEventUpdateOffSystemBookingFunctionParams {
  organisationId: string;
  productId: string;
  eventId: string;
  action: 'increase' | 'decrease';
}

export interface ProductBookingsManagementProps {
  productEvent: DaytimeEventDocument;
}

export const ProductBookingsManagement = ({
  productEvent,
}: ProductBookingsManagementProps) => {
  const { currentOrganisation, firebase } = useAppContext();
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);
  const { env } = useAppStaticQueryConfig();
  if (!productEvent?.id) {
    return null;
  }
  const { id, productId, status } = productEvent;

  const onUpdateOffSystemBookingCount = async (action: 'increase' | 'decrease') => {
    if (!firebase || !id || !productId || !currentOrganisation?.id || !action) {
      return;
    }
    try {
      setIsRequestingUpdate(true);
      const daytimeEventCancel = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'organisationFunction-daytimeEventUpdateOffSystemBooking',
      );
      const params: DaytimeEventUpdateOffSystemBookingFunctionParams = {
        organisationId: currentOrganisation.id,
        productId,
        eventId: id,
        action,
      };
      await daytimeEventCancel(params);
    } catch (err) {
      console.warn('err: ', err);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  const isDebug = false;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <StringPropertyRow title={'Event date id:'} value={id || 'N/A'} />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow
          title={'Orders in system'}
          value={productEvent.orders?.length?.toString() || 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <StringPropertyRow
          title={'Orders off system'}
          value={productEvent.offSystemOrderCount?.toString() || 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color={'primary'} disabled>
          <Typography>Show booking list</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth color={'primary'} disabled>
          <Typography>Show onboarded list</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={isRequestingUpdate || status === 'cancelled'}
          fullWidth
          color={'primary'}
          onClick={() => onUpdateOffSystemBookingCount('increase')}
        >
          <Typography>Add off system booking</Typography>
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={isRequestingUpdate || status === 'cancelled'}
          fullWidth
          color={'primary'}
          onClick={() => onUpdateOffSystemBookingCount('decrease')}
        >
          <Typography>Remove off system booking</Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DebugObject isDebug={isDebug} object={productEvent || {}} />
      </Grid>
    </Grid>
  );
};
