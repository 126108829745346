import * as React from 'react';
import { AppProps } from 'src/app/types';
import { Container } from 'src/app/components';
import { OrganisationConfirmation } from './OrganisationConfirmation';

export interface OrganisationConfirmationPageProps extends AppProps {
  id?: string;
}

export const OrganisationConfirmationPage = (
  props: OrganisationConfirmationPageProps,
) => {
  const { id, location } = props;
  return (
    <Container>
      <OrganisationConfirmation location={location} params={{ id: id || '' }} />
    </Container>
  );
};
