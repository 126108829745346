import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { navigate } from 'gatsby';

import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks'; // TODO: select systemId based on system user data
import { DeleteMessageData, RespondToMessageData } from 'src/app/types';

import { AdminMenuContainer } from 'src/admin/components';
import { AdminScreenProps } from 'src/admin/types';

import { MessagesRouter } from 'src/app/container';
import { MessageType, MessageCollection } from 'src/app/constants';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';

const messageTypeCollectionsOptions: MessageType[] = [
  // MessageType.LOG,
  MessageType.INBOUND,
  MessageType.OUTBOUND,
];

const messageCollectionWriteOptions: MessageCollection[] = [
  // MessageCollection.ORGANISATION,
  // MessageCollection.USER,
  MessageCollection.SYSTEM, // FIXME: DEBUG only
];

const collectionPath = 'message_organisation'; // TODO: create constants for all use cases

// const systemId = 'holiday'; // TODO: select systemId based on system user data
const routerBasePath = '/admin/partner/communication';

/**
 * System messages screen - Show In/Out/Log system messages
 * @version 1.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationCommunicationScreen = (props: AdminScreenProps) => {
  const { /* currentSystem, */ firebase, organisations, currentOrganisation } =
    useAppContext(); // TODO: select systemId based on system user data
  const { env } = useAppStaticQueryConfig();

  const systemId = 'holiday'; // TODO

  const onRespondToMessage = async (messageId: string, messageText: string) => {
    if (!firebase || !currentOrganisation?.id) {
      return;
    }

    const respondToMessage = httpsCallable(
      getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
      'commonFunctions-respondToMessage',
    );

    try {
      const params: RespondToMessageData = {
        collection: collectionPath,
        messageId,
        systemId: currentOrganisation.id,
        responseText: messageText,
      };

      await respondToMessage(params); // make function non async?
      // TODO: show notifications
      navigate(`${routerBasePath}`);
    } catch (err) {
      console.log('Got error: ', err);
    }
  };

  const onDeleteMessage = async (messageId: string) => {
    if (!firebase) {
      return;
    }

    const deleteMessage = httpsCallable(
      getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
      'commonFunctions-deleteMessage',
    );

    try {
      const params: DeleteMessageData = {
        collection: collectionPath,
        messageId,
        systemId,
      };

      await deleteMessage(params); // make function non async?
      // TODO: show notifications
      navigate(`${routerBasePath}`);
    } catch (err) {
      console.log('Got error: ', err);
    }
  };

  if (!currentOrganisation) {
    return null;
  }

  return (
    <AdminMenuContainer {...props} i18nNS={'organisation'} screenId={'org-communication'}>
      <MessagesRouter
        messageCollectionPath={collectionPath}
        messageCollectionsWriteOptions={messageCollectionWriteOptions}
        messageTypeOptions={messageTypeCollectionsOptions}
        onDeleteMessage={(id) => onDeleteMessage(id)}
        onRespondToMessage={(id, message) => onRespondToMessage(id, message)}
        routerBasePath={routerBasePath}
        uid={currentOrganisation.id}
      />
    </AdminMenuContainer>
  );
};
