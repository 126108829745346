import * as React from 'react';
import { Router } from '@reach/router';
import SourceDataCityIcon from '@material-ui/icons/LocationCity';

// import { getOrganisationSourceCollectionPath } from 'src/lib/db';

// import { SourceCollectionName } from 'src/app/constants';
import { Grid, PrivateRoute } from 'src/app/components';

import { ProductDocumentData } from 'src/app/types';

import { AdminMenuContainer } from 'src/admin/components';

import { OrganisationAdminRootScreen } from 'src/app.organisation/constants';

// LOCAL IMPORTS

import {
  ProductEventsRouter,
  ProductEventsRouterProps,
} from '../../../../components/organisationAdminApp/data/productData/productEvents';

import { ProductEditScreen } from '../../../../components/organisationAdminApp/data/productData/productEditModules/ProductEditScreen';

import { CreateScreen } from './ProductCreate.screen';
import { UpdateScreen } from './ProductUpdate.screen';
import { ListScreen } from './ProductList.screen';

// const documentI18nBasePath = 'organisation:document.activityProductDocument';
// const screenI18nBasePath = 'organisation:org-products';
// const collectionName = SourceCollectionName.PRODUCT;
// const collectionPath = (orgId: string) =>
//   getOrganisationSourceCollectionPath(orgId, collectionName);
// const routeBasepath = `/admin/partner/${collectionName}`;
// const routeBasepath = `/admin/partner/${'product'}`;

type DocumentType = ProductDocumentData;

const MenuIcon = () => <SourceDataCityIcon />;
const screenId = OrganisationAdminRootScreen.products;

// const isDebug = isSourceDocumentDebugModeOn(collectionName); // FIXME: remove all that crap or keep for fb remote config feature-wise debugging

// TODO: refactor + RENAME TO ROUTER
export const ProductDocumentScreen = () => {
  const routerBasePath = 'admin/partner/product';
  return (
    <AdminMenuContainer icon={<MenuIcon />} i18nNS={'organisation'} screenId={screenId}>
      <div>
        <Router basepath={routerBasePath} style={{ width: '100%' }}>
          <PrivateRoute<{}> path={'/create/*'} component={CreateScreen} />
          <PrivateRoute<{}>
            path={'/edit/:productCategoryType/:productId'}
            component={ProductEditScreen}
          />
          <PrivateRoute<{}> path={'/:id/*'} component={UpdateScreen} />
          <PrivateRoute<ProductEventsRouterProps>
            path={'/event/:id/*'}
            component={ProductEventsRouter}
            passProps={{ routerBasePath: `${routerBasePath}/event` }}
          />
          <PrivateRoute<{}> path={'/'} component={ListScreen} />
        </Router>
      </div>
    </AdminMenuContainer>
  );
};
