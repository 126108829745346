import * as React from 'react';

import { AdminMenuContainer } from 'src/admin/components';
import { AdminScreenProps } from 'src/admin/types';
import { Grid } from 'src/app/components';

import { UploadDocument } from './UploadDocument'; // TODO: make generic

const i18nNS = 'organisation';
const i18nScreenId = 'org-documents';
const i18nPath = `${i18nNS}:${i18nScreenId}`;

/**
 * OrganisationDocumentsScreen
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationDocumentsScreen = ({ icon }: AdminScreenProps) => {
  // const { currentOrganisation } = useAppContext();

  return (
    <AdminMenuContainer icon={icon} i18nNS={'organisation'} screenId={'org-documents'}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UploadDocument
            documentName={'organisationInsuranceDocument'}
            i18nPath={`${i18nPath}.document.organisation-insurance`}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadDocument
            documentName={'organisationLicenceDocument'}
            i18nPath={`${i18nPath}.document.organisation-licence`}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadDocument
            documentName={'organisationRegistrationDocument'}
            i18nPath={`${i18nPath}.document.organisation-registration`}
          />
        </Grid>
      </Grid>
    </AdminMenuContainer>
  );
};
