import * as React from 'react';
import { useParams, useLocation } from '@reach/router';

import {
  CenteredContainer,
  ButtonTw,
  FirestoreDocumentFetch,
  Grid,
  LoadingIndicator,
  Typography,
} from 'src/app/components';
import { ContentItemScreen } from 'src/app.consumer/components';

import {
  AppLocale,
  GatsbyNodeType,
  NotificationType,
  SourceCollectionName,
} from 'src/app/constants';
import {
  ContentNavigationType,
  ProductLocalization,
  StoredImageData,
} from 'src/app/types';

import {
  useSourcedLocations,
  useAppContext,
  useLocalizationContext,
} from 'src/app/hooks';

import {
  getOrganisationSourceCollectionPath,
  getLocalizationNamespaceDocumentPath,
} from 'src/lib/db';
import { FirestoreDocData } from 'src/app/container';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, CollectionReference, getDocs } from 'firebase/firestore';

export interface DocumentPreviewScreenProps {
  collectionName: SourceCollectionName;
  nodeType: GatsbyNodeType;
}

// TODO: merge with other preview screen?
type M = {}; // TODO: type of MediaDocData

export const DocumentPreviewScreen = (props: DocumentPreviewScreenProps) => {
  console.log('shadgjsahdgjsgdj',props);

  const { collectionName, nodeType } = props;
  const firestore = useFirestore();
  const { createNotification, currentOrganisation } = useAppContext();
  const locations = useSourcedLocations();
  const { id: paramsId } = useParams();
  const location = useLocation();

  const [localizationDocData, setLocalizationDocData] =
    React.useState<ProductLocalization | null>(null);
  const [mediaDocData, setMediaDocData] = React.useState<M | null>(null); // TODO: type
  const [imageUrls, setImageUrls] = React.useState<M | null>(null); // TODO: type

  const { language, setLanguage } = useLocalizationContext();

  React.useEffect(() => {
    fetchImages();
  }, []);

  if (!paramsId) {
    return null;
  }

  const currentUrl = new URL(location.href);
  const urlSearchParams = new URLSearchParams(currentUrl.search);

  // NOTE: l for language
  if (!urlSearchParams.has('l')) {
    return null;
  }

  const localeId = urlSearchParams.get('l');
  if (localeId) setLanguage(localeId as AppLocale);
  const organisationIdFromUrl = urlSearchParams.get('o') || null; // NOTE: hack for system admin users

  const collectionPath = getOrganisationSourceCollectionPath(
    organisationIdFromUrl || currentOrganisation!.id,
    collectionName,
  );

  const fetchImages = async () => {
    const imageCollectionRef = collection(
      firestore,
      `${collectionPath}/${paramsId}/image`,
    );
    const imageSnaps = await getDocs(imageCollectionRef);
    console.log(imageSnaps,'image snaps ')
    const sourceProductDocumentImages: StoredImageData[] = [
      ...imageSnaps.docs
        .filter((doc, idx) => doc.exists() && idx < 10)
        .map((doc) => doc.data() as StoredImageData),
    ];
    setImageUrls(sourceProductDocumentImages);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <div className='p-2'>
          <ButtonTw onClick={() => window.close()} size={'sm'} variant={'outline'} rounded>
            Close Preview
          </ButtonTw>
        </div>
      </Grid>
      {!localizationDocData ? (
        <Grid item xs={12}>
          <CenteredContainer>
            <Typography color={'error'}>
              {`Translation (${localeId}) not found`}
            </Typography>
          </CenteredContainer>
        </Grid>
      ) : null}
      <FirestoreDocumentFetch<ProductLocalization> // TODO: LocalizationDocument interface needed
        documentPath={`${collectionPath}/${paramsId}/localization/${localeId}`}
        onSuccess={(data) => setLocalizationDocData(data)}
        onError={() =>
          createNotification(
            NotificationType.ERROR,
            `Error loading ${localeId} translation`,
          )
        }
      />
      {true ? (
        <FirestoreDocumentFetch<M> // TODO: MediaDoc interface needed
          // documentPath={`${collectionPath}/${paramsId}/media/${mediaId}`}
          documentPath={`${collectionPath}/${paramsId}/media/${'youtube'}`} // NOTE: no need for id with const 'youtube'
          onSuccess={(data) => setMediaDocData(data)}
          onError={() =>
            createNotification(
              NotificationType.ERROR,
              `Error loading ${'youtube'} videos`,
            )
          }
        />
      ) : null}
      <Grid item xs={12}>
        <FirestoreDocData<ContentNavigationType>
          documentPath={`${collectionPath}/${paramsId}`}
          noIdField
          renderElements={({ data, status }) => {
            if (status === 'loading') {
              return <LoadingIndicator />;
            }
            // TODO: this is a mess without proper usage of interfaces
            const dataLocalizations: ProductLocalization[] = localizationDocData
              ? [{ ...localizationDocData }]
              : [];

            const screenData = {
              ...data,
              localizations: dataLocalizations,
              imageFiles: imageUrls,
            };
            if (mediaDocData) {
              const mediaDocLocalization = mediaDocData.localizations?.find(
                (loc) => loc.localeId === localeId,
              );
              if (mediaDocLocalization && dataLocalizations.length) {
                // console.log('dataLocalizations: ', dataLocalizations);
                dataLocalizations[0].media = {
                  youtube: mediaDocLocalization.media?.youtube || [],
                };
              }
            }

             console.log('screenData: ', screenData);
            return status !== 'success' || !data.id ? null : (
              <Grid container>
                <Grid item xs={12}>
                  <ContentItemScreen
                    collectionMappings={
                      [
                        // {
                        //   id: GatsbyNodeType.COUNTRY,
                        //   collectionItems: countries,
                        //   collectionItemIdPropertyName: 'continentId',
                        // },
                      ]
                    }
                    itemLocations={locations}
                    itemType={nodeType}
                    screenItemData={screenData}
                    nextItemId={''}
                    prevItemId={''}
                    previewLanguage={localeId}
                  />
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
