import * as React from 'react';
import { navigate } from 'gatsby';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { ButtonTw, Localized } from 'src/app/components';
import { AdminMenuContainer } from 'src/admin/components';
import { AdminScreenProps } from 'src/admin/types';

const i18nNS = 'organisation';
const screenId = 'org-settings';
const i18nPath = `${i18nNS}:${screenId}`;
const settingsEmailI18nPath = `${i18nPath}-email`;
const setingsPayoutI18nPath = `${i18nPath}-payout`;

export interface OrganisationSettingsNavigationProps extends AdminScreenProps {}

export const OrganisationSettingsNavigation = ({
  icon,
}: // TODO: implement or remove
// i18nNS,
// screenId,
OrganisationSettingsNavigationProps) => {
  return (
    <AdminMenuContainer icon={icon} i18nNS={'organisation'} screenId={'org-settings'}>
      <div className='py-4 flex flex-wrap gap-3 items-center'>
        <ButtonTw variant={'accent'} rounded size='lg' onClick={() => navigate('email')}>
          <Localized dictKey={`${settingsEmailI18nPath}.nav label`} />
        </ButtonTw>
        <ButtonTw variant={'accent'} rounded size='lg' onClick={() => navigate('payout')}>
          <Localized dictKey={`${setingsPayoutI18nPath}.nav label`} />
        </ButtonTw>
        <div className='w-full'>
          <ButtonTw
            variant={'text'}
            onClick={() => navigate('../')}
            className='underline !text-accent-500 !font-semibold hover:!text-neutral-900 dark:hover:!text-neutral-50'
          >
            <NavigateBeforeIcon /> <Localized dictKey={`${i18nPath}.action.navigate-back.label`} />
          </ButtonTw>
        </div>
      </div>
    </AdminMenuContainer>
  );
};
