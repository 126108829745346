import * as React from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useAdminContext } from 'src/admin/context';
import { useAppContext } from 'src/app/hooks';
import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  LoadingIndicator,
  Localized,
  Typography,
} from 'src/app/components';
import { NotificationType } from 'src/app/constants';
import { bytesToSize, truncateString, onOpenInNewWindow } from 'src/app/lib';
import { navigate } from 'gatsby';

interface UploadDocumentProps {
  documentName: string;
  i18nPath: string;
}

// TODO: use short lived urls for downloads via functions call
// TODO: refactor and make generic component
export const UploadDocument = (props: UploadDocumentProps) => {
  const { documentName, i18nPath } = props;
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));
  const { currentOrganisation, createNotification, firebase } = useAppContext();
  const { document } = useAdminContext();

  const [file, setFile] = React.useState<File | null>(null);
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  const onDisplayDocument = async () => {
    try {
      if (
        !firebase ||
        !currentOrganisation?.id ||
        !documentName ||
        !document?.organisationUploadDocuments?.[documentName]
      ) {
        return null;
      }

      const storage = getStorage(firebase);
      const fileRef = ref(storage, document.organisationUploadDocuments?.[documentName]);
      const downloadUrl = await getDownloadURL(fileRef);

      if (downloadUrl) {
        onOpenInNewWindow(downloadUrl);
      }
    } catch (err) {
      console.warn(err);
    }
    return null;
  };

  const onSubmit = async () => {
    try {
      if (!firebase || !currentOrganisation?.id || !documentName) {
        return null;
      }
      setIsRequestingUpdate(true);

      const fileName = `${documentName}_${new Date().getTime()}.pdf`;

      const storage = getStorage(firebase);
      const fileStorageRef = ref(
        storage,
        `organisation/${currentOrganisation.id}/document/${documentName}/${fileName}`,
      );

      const metaData = {};

      if (!file) {
        createNotification(NotificationType.ERROR, 'No file', 5000);
        return null;
      }
      const data = await file;

      // console.log('data: ', data);
      // console.log('data: ', data?.size);
      // console.log('data: ', data?.name);
      // console.log('data: ', data?.type);
      // console.log('data: ', data?.lastModified);
      // console.log('data: ', data?.text);

      if (!data || data.type !== 'application/pdf') {
        createNotification(NotificationType.ERROR, 'Invalid file', 5000);
        return null;
      }
      // await storageDocRef.put(data);
      await uploadBytes(fileStorageRef, data);
      createNotification(NotificationType.SUCCESS, 'Document upload successful', 5000);
      setFile(null);
      navigate(`?update=${new Date().getTime()}`);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsRequestingUpdate(false);
    }
    return null;
  };

  if (!document) {
    return null;
  }

  let isUploadButtonDisabled = false;
  // TODO: disable updates after one upload? => only in dialog with company?
  // if (document.organisationUploadDocuments[documentName]) {
  //   isUploadButtonDisabled = true;
  // }
  if (!file || file.type !== 'application/pdf') {
    isUploadButtonDisabled = true;
  }

  if (isRequestingUpdate) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container spacing={6} className='px-4 mt-4'>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'h6'} renderAs={'h2'}>
              <Localized dictKey={`${i18nPath}.title`} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'} renderAs={'p'} className='px-2'>
              <Localized dictKey={`${i18nPath}.description`} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ButtonGroup
          className='px-2'
          fullWidth
          orientation={isWiderMD ? 'horizontal' : 'vertical'}
          variant={'outlined'}
          size={'small'}
          style={{ fontSize: '0.5rem' }}
        >
          <Button
            onClick={onDisplayDocument}
            disabled={!document?.organisationUploadDocuments?.[documentName]}
          >
            <Typography>
              <Localized dictKey={`${i18nPath}.action.download-document`} />
            </Typography>
          </Button>
          <Button component={'span'}>
            <label
              htmlFor={`upload-org-document_${documentName}`}
              style={{ width: '100%' }}
            >
              <input
                style={{ display: 'none', width: '100%' }}
                id={`upload-org-document_${documentName}`}
                name={`upload-org-document_${documentName}`}
                type={'file'}
                className={'fileUpload'}
                onChange={(e) => setFile(e.target?.files?.[0] || null)}
                accept={'application/pdf'}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography align={'center'}>
                    {file ? (
                      `${truncateString(file.name, 24, '...')} ${bytesToSize(file.size)}`
                    ) : (
                      <Localized dictKey={`${i18nPath}.action.select-document`} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </label>
          </Button>
          <Button onClick={onSubmit} disabled={isUploadButtonDisabled}>
            <Typography>
              <Localized dictKey={`${i18nPath}.action.upload-document`} />
            </Typography>
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
