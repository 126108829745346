import * as React from 'react';
import { Router } from '@reach/router';

import {
  Route,
  Divider,
  Grid,
  Localized,
  SettingOption,
  Tab,
  Tabs,
} from 'src/app/components';

import { AdminMenuContainer } from 'src/admin/components';
import { useAppContext } from 'src/app/hooks';
import { AdminScreenProps } from 'src/admin/types';

import { OrganisationSettingsRouter } from './OrganisationSettingsRouter';

export interface SettingsProps {
  config: SettingsConfig[];
  onChange: () => void;
}

const Settings = (props: SettingsProps) => {
  const { config, onChange } = props;
  return (
    <Grid container spacing={4} style={{ padding: '16px' }}>
      {config?.length
        ? config.map((cfg) => (
            <Grid key={cfg.id} item xs={12}>
              <SettingOption
                checked={cfg.value}
                description={
                  <Localized
                    dictKey={`organisation:screen-settings.setting.${cfg.id}.description`}
                  />
                }
                label={
                  <Localized
                    dictKey={`organisation:screen-settings.setting.${cfg.id}.label`}
                  />
                }
                onChange={onChange}
                name={'settings-x-checkbox'}
              >
                <Divider />
              </SettingOption>
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export interface BaseSettingsConfig {
  id: string;
  config: SettingsConfig[];
}

export interface SettingsConfig {
  id: string;
  value: boolean;
}

const organisationSettingsConfig: BaseSettingsConfig[] = [
  {
    id: 'Organisation Settings',
    config: [
      {
        id: 'notify_via_email',
        value: true,
      },
      {
        id: 'notify_via_webhook',
        value: true,
      },
    ],
  },
  {
    id: 'Report Settings',
    config: [
      {
        id: 'notify_on_every_report',
        value: true,
      },
    ],
  },
  {
    id: 'Media Settings',
    config: [
      {
        id: 'auto_resize_images',
        value: true,
      },
      {
        id: 'limit_image_slots',
        value: true,
      },
    ],
  },
  {
    id: 'Order Settings',
    config: [
      {
        id: 'notify_on_every_order',
        value: true,
      },
      {
        id: 'notify_on_every_order_cancellation',
        value: true,
      },
    ],
  },
  {
    id: 'Product Settings',
    config: [
      {
        id: 'publish_after_review',
        value: true,
      },
      {
        id: 'publish_without_review',
        value: false,
      },
    ],
  },
  {
    id: 'User Settings',
    config: [
      {
        id: 'users_managed_by_owner',
        value: true,
      },
    ],
  },
];

/**
 * OrganisationSettingsScreen
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationSettingsScreen = ({ icon }: AdminScreenProps) => {
  const { currentOrganisation } = useAppContext();
  const [tabIdx, setTabIdx] = React.useState(0);

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIdx(newValue);
  };

  const onChangeSettingValue = () => {};

  return <OrganisationSettingsRouter routerBasePath={'/admin/partner/settings'} />;
};
