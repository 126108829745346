import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import { UserBillingInformationData } from 'src/app/types';
import { OrganisationBankSettings } from 'src/admin/types';
import { FormError } from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  bankName: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
  bankAddressStreet: yup
    .string()
    .required(FormError.REQUIRED)
    .max(32, FormError.NUMBER_MAX_VALUE),
  bankAddressStreetNumber: yup
    .string()
    .required(FormError.REQUIRED)
    .max(5, FormError.NUMBER_MAX_VALUE),
  bankAddressZip: yup
    .string()
    .required(FormError.REQUIRED)
    .max(16, FormError.NUMBER_MAX_VALUE),
  bankAddressCity: yup
    .string()
    .required(FormError.REQUIRED)
    .max(32, FormError.NUMBER_MAX_VALUE),
  bankAddressCountry: yup
    .string()
    .required(FormError.REQUIRED)
    .max(32, FormError.NUMBER_MAX_VALUE),
  iban: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
  bic: yup.string().required(FormError.REQUIRED).max(32, FormError.NUMBER_MAX_VALUE),
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: OrganisationBankSettings = {
  bankName: '',
  bankAddressStreet: '',
  bankAddressStreetNumber: '',
  bankAddressZip: '',
  bankAddressCity: '',
  bankAddressCountry: '',
  iban: '',
  bic: '',
};

export const defaultValues__DEV: OrganisationBankSettings = {
  bankName: 'bankName',
  bankAddressStreet: 'bankAddressStreet',
  bankAddressStreetNumber: '101',
  bankAddressZip: 'bankAddressZip',
  bankAddressCity: 'bankAddressCity',
  bankAddressCountry: 'bankAddressCountry',
  iban: 'iban',
  bic: 'bic',
  testFail: 'plz fail', // Test, should not be accepted with that property
};
