import * as React from 'react';

import { Glass, Grid, Typography, Divider } from 'src/app/components';
import { SignupFormContainer } from 'src/app/components/appAccount/Signup/SignupFormContainer';

export interface OrganisationMemberInvitationProps {
  location: {
    search: string;
  };
  params: {
    id: string;
  };
}

const OrganisationMemberInvitation = (props: OrganisationMemberInvitationProps) => {
  const { params } = props;

  return (
    <Glass
      className='my-4 py-8 px-4 min-h-[450px]'
      // elevation={1}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography paragraph align={'center'} variant={'h3'} renderAs={'h1'}>Hey Holiday - Organisation member signup</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography paragraph align={'center'} variant={'h4'} renderAs={'p'}>Important information:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography paragraph align={'center'} variant={'h4'} renderAs={'p'}>You have to use the same email you were invited with</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={2}>
              </Grid>
              <Grid item xs={12} md={8}>
                <SignupFormContainer signupType={'organisation'} />
              </Grid>
              <Grid item xs={12} md={2}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Glass>
  );
};

export { OrganisationMemberInvitation };
