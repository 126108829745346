import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
  Button,
  Divider,
  GlassPaper,
  Grid,
  LoadingIndicator,
  Localized,
  Typography,
} from 'src/app/components';
import { useAdminContext } from 'src/admin/context';
import { useAppContext } from 'src/app/hooks';
import { NotificationType, OrganisationEmailNotificationType } from 'src/app/constants';

import {
  // BaseOrganisationDocument,
  OrganisationNotificationSettings,
} from 'src/admin/types';

import { getOrganisationUserDisplayName } from 'src/app.organisation/lib';

import { AddUserToNotifications } from './AddUserToNotifications';
import { EmailNotificationManagementListUser } from './EmailNotificationManagementListUser';

// interface EmailPoolUserAddFunctionParams {
//   notificationType: OrganisationEmailNotificationType;
//   organisationId: string;
//   userId: string; // user id to add to the pool
// }

interface EmailPoolUserRemoveFunctionParams {
  notificationType: OrganisationEmailNotificationType;
  organisationId: string;
  userId: string; // user id to remove from the pool
}

// export interface AddUserToNotificationsProps {
//   notificationType: OrganisationEmailNotificationType;
//   onClose: () => void;
//   subscribedUserIds: string[];
//   // notificationSettings: OrganisationNotificationSettings;
// }

const i18nNS = 'organisation';
const screenId = 'org-settings';
const i18nPath = `${i18nNS}:${screenId}`;
const settingsEmailI18nPath = `${i18nPath}-email`;

export interface EmailNotificationManagementProps {
  notificationType: OrganisationEmailNotificationType;
  notificationSettings: OrganisationNotificationSettings;
}

export const EmailNotificationManagement = ({
  notificationType,
  notificationSettings,
}: EmailNotificationManagementProps) => {
  const { firebase, createNotification } = useAppContext();
  const { document: organisationDocument } = useAdminContext();
  const [isAddUserMenuOpen, setIsAddUserMenuOpen] = React.useState<boolean>(false);
  const [isRequestingUpdate, setIsRequestingUpdate] = React.useState<boolean>(false);

  console.log('organisationDocument: ', organisationDocument);

  const onRemoveUser = async (id: string) => {
    if (!firebase || !id || !organisationDocument?.id || !notificationType) {
      return;
    }

    try {
      setIsRequestingUpdate(true);
      const request = httpsCallable(
        getFunctions(firebase),
        'organisationFunction-emailPoolUserRemove',
      );
      const params: EmailPoolUserRemoveFunctionParams = {
        organisationId: organisationDocument.id,
        notificationType,
        userId: id,
      };
      await request(params);
      createNotification(NotificationType.SUCCESS, 'Success', 5000);
    } catch (err) {
      console.warn('Error: ', err);
      createNotification(NotificationType.ERROR, 'Error', 5000);
    } finally {
      setIsRequestingUpdate(false);
    }
  };

  if (!notificationSettings) {
    return null;
  }

  if (isRequestingUpdate) {
    return <LoadingIndicator />;
  }

  return (
    <GlassPaper elevation={2} className='px-4 py-8'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h6'} renderAs={'h2'}>
            <Localized
              dictKey={`${settingsEmailI18nPath}.notification.${notificationType}.title`}
            />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'body1'}>
            <Localized
              dictKey={`${settingsEmailI18nPath}.notification.${notificationType}.description`}
            />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} className='px-4'>
            {notificationSettings?.[notificationType]
              ? notificationSettings?.[notificationType].map((id) => {
                  return (
                    <Grid item xs={12} key={id}>
                      <EmailNotificationManagementListUser
                        onButtonClick={() => onRemoveUser(id)}
                        buttonLabel={
                          <Localized
                            dictKey={`${settingsEmailI18nPath}.action.user-remove.label`}
                          />
                        }
                        userName={
                          organisationDocument
                            ? getOrganisationUserDisplayName(organisationDocument, id)
                            : id
                        }
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isAddUserMenuOpen ? (
            <Grid container spacing={2} className='px-4'>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AddUserToNotifications
                  notificationType={notificationType}
                  subscribedUserIds={notificationSettings?.[notificationType] || []}
                  onClose={() => setIsAddUserMenuOpen(false)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container className='px-4'>
              <Grid item xs={12}>
                <Button
                  disabled={notificationSettings?.[notificationType].length >= 25}
                  variant={'outlined'}
                  fullWidth
                  onClick={() => setIsAddUserMenuOpen(true)}
                >
                  <Localized
                    dictKey={`${settingsEmailI18nPath}.action.user-add-menu-open.label`}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </GlassPaper>
  );
};
