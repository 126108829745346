import * as React from 'react';

import { Router } from '@reach/router';

import { Route } from 'src/app/components';

import SettingsIcon from '@material-ui/icons/Settings';

import {
  OrganisationSettingsNavigation,
  OrganisationSettingsNavigationProps,
} from './OrganisationSettingsNavigation';

import {
  OrganisationEmailSettings,
  OrganisationEmailSettingsProps,
} from './OrganisationEmailSettings';

import {
  OrganisationPayoutSettings,
  OrganisationPayoutSettingsProps,
  UpdatePayoutInformation,
  UpdatePayoutInformationProps,
} from './OrganisationPayoutSettings';

export interface OrganisationSettingsRouterProps {
  routerBasePath: string;
}

export const OrganisationSettingsRouter = ({
  routerBasePath,
}: OrganisationSettingsRouterProps) => {
  return (
    <Router basepath={routerBasePath}>
      <Route<OrganisationEmailSettingsProps>
        path={'/email'}
        component={OrganisationEmailSettings}
        passProps={{ icon: <SettingsIcon /> }}
      />
      <Route<OrganisationPayoutSettingsProps>
        path={'/payout'}
        component={OrganisationPayoutSettings}
        passProps={{ icon: <SettingsIcon /> }}
      />
      <Route<UpdatePayoutInformationProps>
        path={'/payout/update'}
        component={UpdatePayoutInformation}
        passProps={{ icon: <SettingsIcon /> }}
      />
      <Route<OrganisationSettingsNavigationProps>
        path={'/'}
        component={OrganisationSettingsNavigation}
        passProps={{ icon: <SettingsIcon /> }}
      />
    </Router>
  );
};
