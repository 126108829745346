import * as React from 'react';
import { Divider, Grid, Typography } from 'src/app/components';

// import { FormatSizeRounded } from '@material-ui/icons';

import { AdminMenuContainer } from 'src/admin/components';
import { useAppContext } from 'src/app/hooks';
import { AdminScreenProps } from 'src/admin/types';
import { OrganisationUserList } from './OrganisationUserList';
import { InviteUser } from './InviteUser';

// TODO: wrap in transactions to handle concurrency issues with other userAdmins?
/**
 * OrganisationUserScreen
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationUserScreen = ({ icon }: AdminScreenProps) => {
  const { currentOrganisation } = useAppContext();

  return currentOrganisation?.id ? (
    <AdminMenuContainer icon={icon} i18nNS={'organisation'} screenId={'org-users'}>
      <Grid container>
        <Grid item xs={12}>
          <div className='item-container'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography paragraph variant={'h5'} renderAs={'h2'}>
                  Invite another user to organisation
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <InviteUser />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='item-container'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography paragraph variant={'h5'} renderAs={'h2'}>
                  Organisation users
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <OrganisationUserList
                  collectionType={'user'}
                  organisationId={currentOrganisation.id}
                  permissionController
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='item-container'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography paragraph variant={'h5'} renderAs={'h2'}>
                  Invited users
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '16px' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <OrganisationUserList
                  collectionType={'user_invitation'}
                  organisationId={currentOrganisation.id}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </AdminMenuContainer>
  ) : null;
};
