import * as React from 'react';
import { Divider, Grid, Typography, StringPropertyRow } from 'src/app/components';

import { AdminMenuContainer } from 'src/admin/components';
import { useAppContext } from 'src/app/hooks';
import { AdminScreenProps } from 'src/admin/types';

// import { GeoMap } from 'src/app.organisation/Modules/GeoMap';

import { useAdminContext } from 'src/admin/context';

/**
 * OrganisationDashboardScreen
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationDashboardScreen = ({ icon }: AdminScreenProps) => {
  const { currentOrganisation } = useAppContext();
  const { document } = useAdminContext();

  return (
    <AdminMenuContainer icon={icon} i18nNS={'organisation'} screenId={'org-organisation'}>
      {/* <Grid item xs={12} md={9}>
        <Glass lowShade className={styles.dashboardPrimary}>
          <GeoMap width={350} height={350} />
        </Glass>
      </Grid> */}
      <Grid container spacing={2}>
        {document?.organisation?.organisationName ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography paragraph variant={'h5'} renderAs={'p'}>
                  {document?.organisation?.organisationName || ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {document ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <StringPropertyRow
                  title={'isOrganisationDocumentsValid'}
                  value={document?.isOrganisationDocumentsValid ? 'YES' : 'NO'}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {document ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <StringPropertyRow
                  title={'isOrganisationUnlocked'}
                  value={document?.isOrganisationUnlocked ? 'YES' : 'NO'}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {document?.isOrganisationDisabled ? (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <StringPropertyRow
                    title={'isOrganisationDisabled'}
                    value={document?.isOrganisationDisabled ? 'YES' : 'NO'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* <Grid item xs={12} md={3}>
        <Glass lowShade className={styles.dashboardSecondary}>
          <div style={{ padding: '16px' }}>
            <Typography variant={'h6'} renderAs={'h2'}>
              Revenues
            </Typography>
            GLOBAL/SELECTED CNTR
          </div>
        </Glass>
      </Grid>
      <Grid item xs={12} md={7}>
        <Glass lowShade className={styles.dashboardTertiary}>
          <Typography variant={'h6'} renderAs={'h2'}>
            Next Activities
          </Typography>
          GLOBAL/SELECTED CNTR
        </Glass>
      </Grid>
      <Grid item xs={12} md={5}>
        <Glass lowShade className={styles.dashboardQuaternary}>
          <Typography variant={'h6'} renderAs={'h2'}>
            Top 5 Activities
          </Typography>
          GLOBAL/SELECTED CNTR
        </Glass>
      </Grid> */}
      </Grid>
    </AdminMenuContainer>
  );
};
