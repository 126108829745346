import * as React from 'react';

import { Router } from '@reach/router';

import { Route } from 'src/app/components';
import {
  ProductEventContainer,
  ProductEventContainerProps,
} from './ProductEventContainer';
import {
  ProductEventBookingsContainer,
  ProductEventBookingsContainerProps,
} from './ProductEventBookingsContainer';

export interface ProductEventRouterProps {
  eventId?: string; // ReachRouter prop
  productId?: string; // ReachRouter prop
  routerBasePath: string;
}

export const ProductEventRouter = ({
  eventId,
  productId,
  routerBasePath,
}: ProductEventRouterProps) => {
  return !eventId || !productId ? null : (
    <Router basepath={routerBasePath}>
      <Route<ProductEventContainerProps>
        path={'/:eventId'}
        component={ProductEventContainer}
        passProps={{ productId }}
      />
      <Route<ProductEventBookingsContainerProps>
        path={'/:eventId/bookings'}
        component={ProductEventBookingsContainer}
        passProps={{ productId }}
      />
      {/** //TODO: */}
      {/* <Route<ProductEventContainerProps>
        path={'/:eventId/order'}
        component={ProductEventOrderRouter}
        passProps={{ productId }}
      /> */}
    </Router>
  );
};
