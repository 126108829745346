import * as React from 'react';

import { OrganisationSignup } from './organisationSignup';

// import { AppProps } from 'src/app/types';

// No container component wrapping here like in other screens...
export const OrganisationSignupPage = (/* props: AppProps */) => {
  return <OrganisationSignup />;
};
