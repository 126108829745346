import * as React from 'react';

import { ButtonTw, Localized } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { OrganisationSignupFormData } from 'src/app.organisation/types';

const i18nPath = 'organisation:/organisation/signup';

export interface SupplierRegistrationConfirmationProps {
  orgSignupData: OrganisationSignupFormData;
}

export const SupplierRegistrationConfirmation = (
  props: SupplierRegistrationConfirmationProps,
) => {
  const { orgSignupData } = props;
  console.log('orgSignupData', JSON.stringify(props));
  const { closeNotification } = useAppContext();
  const onShoppingCartNavigationClick = () => {
    closeNotification();
  };
  return (
    <div className='flex flex-col gap-y-8 py-8 md:p-8'>
      <h3 className='text-h4 text-center text-accent-500 font-semibold'>
        <Localized
          dictKey={`${i18nPath}.congrats.title`}
          defaultValue={`Congratulations ${
            orgSignupData?.firstName || ''
          } you have successfully registered your company!`}
          translationOptions={{ firstName: orgSignupData?.firstName }}
        />
      </h3>
      <div className='text-p2 text-center'>
        <Localized
          dictKey={`${i18nPath}.congrats.message`}
          defaultValue='Please confirm your registration by activating the link in the email we have sent
        to you.'
        />
      </div>
      <div className='text-center'>
        <ButtonTw
          variant={'accent'}
          rounded
          size='lg'
          className='w-full md:w-auto md:min-w-[270px]'
          onClick={onShoppingCartNavigationClick}
        >
          <Localized dictKey={'common:dialog.action.dialog confirm.label'} />
        </ButtonTw>
      </div>
    </div>
  );
};
