import * as React from 'react';

import { Grid, LocalizedErrorIndicator, LoadingIndicator } from 'src/app/components';
import { DaytimeEventDocument } from 'src/app/types';
import { FirestoreDocData } from 'src/app/container';

import { ProductBookingsManagement } from './ProductBookingsManagement';

export interface ProductEventBookingsContainerProps {
  eventId?: string; // ReachRouter prop
  productId: string;
}

export const ProductEventBookingsContainer = ({
  eventId,
  productId,
}: ProductEventBookingsContainerProps) => {
  if (!productId || !eventId) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* NOTE: when useSubscription doesn't work check reactfire issue: 228 */}
        <FirestoreDocData<DaytimeEventDocument>
          useSubscription
          documentPath={`product/${productId}/daytimeEvent/${eventId}`}
          renderElements={({ data, status }) => {
            if (status === 'loading') {
              return <LoadingIndicator />;
            }
            if (status === 'error') {
              return (
                <LocalizedErrorIndicator
                  i18nContext={'organisation'}
                  i18nErrorCode={'LOADING_DOCUMENT'}
                  i18nErrorAreaCode={'product/daytimeEvent'}
                />
              );
            }
            return <ProductBookingsManagement productEvent={data} />;
          }}
        />
      </Grid>
    </Grid>
  );
};
