import * as React from 'react';
import { navigate } from 'gatsby';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
  ButtonTw,
  Localized,
} from 'src/app/components';
import { OrganisationSignupFormRouter } from './organisationSignupForm';
import { OrganisationSignupInformation } from './OrganisationSignupInformation';

const i18nPath = 'organisation:/organisation/signup';
export const OrganisationSignup = () => {
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));
  const isLandingPage = Boolean(
    typeof window !== 'undefined' && window.location.pathname === '/admin/partner/signup',
  );
  return (
    <>
      {isLandingPage ? (
        <section
          className='font-body flex items-center py-20 px-4 w-full relative bg-cover bg-center h-auto md:py-14 md:min-h-[620px] lg:h-[calc(100vh-300px)]
          before:inset-0 before:absolute before:z-0 before:bg-gradient-to-b before:from-[#0F1A3666] before:to-[#0F1A3610]'
          style={{ backgroundImage: 'url("https://user-images.githubusercontent.com/75487668/141353341-0c11a1a6-89cb-4731-b4c0-c288d2d3c6b0.jpg")' }}
          //style={{ backgroundImage: "url('./pexels-rok-romih-4887433 1.png')" }}
        >
          <div className='xl:container relative'>
            <div className="flex flex-col justify-between gap-y-16 md:gap-y-[140px]">
              <h1 className='text-[2rem] md:text-d1 text-neutral-50'>
                <Localized
                  dictKey={`${i18nPath}.action.signup.prompt.header`}
                />
              </h1>
              <p className='text-center'>
                <ButtonTw
                  size={'xl'}
                  onClick={() => navigate('company-information')}
                  variant='accent'
                  rounded
                  className='w-full sm:w-auto sm:min-w-[270px]'
                >
                  <Localized
                    dictKey={`${i18nPath}.action.signup.button.label`}
                  />
                </ButtonTw>
              </p>
            </div>
          </div>
        </section>
      ) : null}

      {isLandingPage ? (
        <section className="py-9 md:py-20 px-4 dark:text-neutral-50">
          <div className='xl:container'>
            <OrganisationSignupInformation />
          </div>
        </section>
      ) : null}
      <section className="py-8 md:pt-0 md:pb-20 px-4">
        <div className='xl:container'>
          <div className='mx-auto lg:w-10/12 xl:w-8/12'>
            <OrganisationSignupFormRouter />
          </div>
        </div>
      </section>

      {isLandingPage ? (
        <section
          className='font-body flex items-center pt-24 pb-52 md:py-14 px-4 w-full relative bg-cover bg-center md:bg-right-bottom h-auto min-h-auto md:min-h-[620px] lg:h-[calc(100vh-300px)] after:inset-0 after:absolute after:z-0 after:bg-neutral-800 after:bg-opacity-60
          before:inset-0 before:absolute before:z-0 lg:after:bg-opacity-0  lg:before:bg-gradient-to-r lg:before:from-[#0F1A3666] lg:before:to-[#0F1A3610]'
          style={{ backgroundImage: 'url("https://user-images.githubusercontent.com/75487668/141359343-ebf316c7-eec0-4267-847b-d8fb61219edd.jpg")' }}
        >
          <div className='xl:container relative z-10 text-neutral-50'>
            <div className='flex flex-col gap-y-8 lg:w-7/12 xl:w-5/12'>
              <h2 className='text-[1.5rem] md:text-d1 font-bold'>
                <Localized dictKey={`${i18nPath}.action.signup.footer.title`} />
              </h2>
              <p className='text-p1 font-semibold md:text-h3 md:font-bold'>
                <Localized
                  dictKey={`${i18nPath}.action.signup.footer.subtitle`}
                />
              </p>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};
