import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { OrganisationSignupDocumentData } from 'src/app.organisation/types';
import {
  OrganisationTurnoverAmount,
  FormError,
  SocialMediaType,
} from 'src/app/constants';

const createDocumentSchema = yup.object().shape({
  additionalInformation: yup.object().shape({
    localeId: yup.string().required(FormError.REQUIRED),
    organisationEmployeeCount: yup
      .number()
      .positive()
      .integer()
      .required(FormError.REQUIRED),
    organisationFoundedDate: yup.date().required(FormError.REQUIRED),
    organisationYearlyGrossTurnover: yup.string().required(FormError.REQUIRED),
    organisationCategoryTypeIds: yup
      .array()
      .of(yup.string().required(FormError.REQUIRED))
      .min(1), // ensure cast to array
    organisationCategoryIds: yup
      .array()
      .of(yup.string().required(FormError.REQUIRED))
      .min(1), // ensure cast to array
    socialMedia: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string().required(FormError.REQUIRED),
          value: yup.string().url().required(FormError.REQUIRED),
        }),
      )
      .required(FormError.REQUIRED),
    website: yup.string().url().optional(),
  }),
  organisation: yup.object().shape({
    organisationAddressStreet: yup
      .string()
      .required(FormError.REQUIRED)
      .max(32, FormError.NUMBER_MAX_VALUE),
    organisationAddressStreet2: yup.string().optional(),
    organisationAddressStreetNumber: yup
      .string()
      .required(FormError.REQUIRED)
      .max(32, FormError.NUMBER_MAX_VALUE),
    organisationCity: yup
      .string()
      .required(FormError.REQUIRED)
      .max(32, FormError.NUMBER_MAX_VALUE),
    organisationCountry: yup
      .string()
      .required(FormError.REQUIRED)
      .max(32, FormError.NUMBER_MAX_VALUE),
    organisationName: yup
      .string()
      .required(FormError.REQUIRED)
      .max(64, FormError.NUMBER_MAX_VALUE),
    organisationName2: yup.string().optional().max(64, FormError.NUMBER_MAX_VALUE),
    organisationPostOfficeBox: yup
      .string()
      .optional()
      .max(32, FormError.NUMBER_MAX_VALUE),
    organisationZipCode: yup
      .string()
      .required(FormError.REQUIRED)
      .max(16, FormError.NUMBER_MAX_VALUE),
  }),
  owner: yup.object().shape({
    ownerEmail: yup.string().email().required(),
    ownerFirstName: yup.string().required(),
    ownerLastName: yup.string().required(),
    ownerPassword: yup.string().required().min(12),
    // NOTE: both versions are working
    // ownerPasswordConfirmation: yup.string().test('passwords-match', 'Passwords must match', function(value) {
    //   return this.parent.ownerPassword === value;
    // }),
    ownerPasswordConfirmation: yup
      .string()
      .required()
      .min(12)
      .oneOf([yup.ref('ownerPassword'), null], 'Passwords must match'),
    ownerPhone: yup.string().required(),
  }),
  hasReadTermsForOrganisations: yup.boolean().required().isTrue(),
});

export const createDocumentResolver = yupResolver(createDocumentSchema);

export const defaultValues: OrganisationSignupDocumentData = {
  additionalInformation: {
    localeId: 'en',
    organisationEmployeeCount: 1,
    organisationFoundedDate: new Date(),
    organisationYearlyGrossTurnover: OrganisationTurnoverAmount.LESS_THAN_FIFTY,
    organisationCategoryTypeIds: [],
    organisationCategoryIds: [],
    socialMedia: [],
    website: 'https://',
  },
  organisation: {
    organisationAddressStreet: '',
    organisationAddressStreet2: '',
    organisationAddressStreetNumber: '',
    organisationCity: '',
    organisationCountry: '',
    organisationName: '',
    organisationName2: '',
    organisationPostOfficeBox: '',
    organisationZipCode: '',
  },
  owner: {
    ownerEmail: '',
    ownerFirstName: '',
    ownerLastName: '',
    ownerPassword: '',
    ownerPasswordConfirmation: '',
    ownerPhone: '',
  },
  hasReadTermsForOrganisations: false,
};

export const defaultValues__DEV: OrganisationSignupDocumentData = {
  additionalInformation: {
    localeId: 'de',
    organisationEmployeeCount: 4711,
    organisationFoundedDate: new Date(),
    organisationYearlyGrossTurnover: OrganisationTurnoverAmount.ABOVE_MILLION,
    organisationCategoryTypeIds: ['activity-000'],
    organisationCategoryIds: ['banana-ride-000'],
    socialMedia: [
      {
        type: SocialMediaType.YOUTUBE,
        value: 'https://www.youtube.com/channel/UCWw-5E3grqmpeEc6dc1YMrw',
      },
    ],
    website: 'https://www.foo.bar',
  },
  organisation: {
    organisationAddressStreet: 'Foostreet',
    organisationAddressStreet2: 'Foostreet2',
    organisationAddressStreetNumber: '42',
    organisationCity: 'Foocity',
    organisationCountry: 'Foocountry',
    organisationName: 'organisationName',
    organisationName2: 'organisationName2',
    organisationPostOfficeBox: 'organisationPostOfficeBox',
    organisationZipCode: 'zipCode',
  },
  owner: {
    ownerEmail: 'haegele.w@web.de',
    ownerFirstName: 'FooOwnerFirstName',
    ownerLastName: 'FooOwnerLastName',
    ownerPassword: '123456789012',
    ownerPasswordConfirmation: '123456789012',
    ownerPhone: '012345678',
  },
  hasReadTermsForOrganisations: false,
  testFail: 'plz fail', // Test, should not be accepted with that property
};
