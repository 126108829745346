import * as React from 'react';
import { AppProps } from 'src/app/types';
import { Container } from 'src/app/components';
import { OrganisationMemberInvitation } from './OrganisationMemberInvitation';

export interface OrganisationInvitationPageProps extends AppProps {
  id?: string;
  path: string;
}

export const OrganisationInvitationPage = (props: OrganisationInvitationPageProps) => {
  const { id, location } = props;
  return (
    <Container>
      <OrganisationMemberInvitation location={location} params={{ id: id || '' }} />
    </Container>
  );
};
