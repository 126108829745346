import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { useAppContext } from 'src/app/hooks';
import { NotificationType } from 'src/app/constants';
import { Button, Glass, Grid, Typography } from 'src/app/components';
import { getAuth, reload, signOut } from 'firebase/auth';

export interface OrganisationConfirmationProps {
  location: {
    search: string;
  };
  params: {
    id?: string;
  };
}

// TODO: loading
const OrganisationConfirmation = (props: OrganisationConfirmationProps) => {
  const {
    params: { id },
  } = props;
  const { firebase, createNotification } = useAppContext();

  const onConfirmRegistrationEmail = async () => {
    try {
      if (!firebase || !id) {
        return;
      }

      const confirmOrganisationRegistration = httpsCallable(
        getFunctions(firebase),
        'organisationFunction-organisationConfirmation',
      );

      const res = await confirmOrganisationRegistration({ id: id || null });

      // TODO: try to logout the user after success => to circumvent the relogin procedure...
      const auth = getAuth(firebase);
      if (auth?.currentUser) {
        await reload(auth.currentUser);
        await signOut(auth);
      }

      if (res) {
        console.log('success: ', res.data);
        createNotification(
          NotificationType.SUCCESS,
          <div>Organisation successfully registered</div>,
          6000,
        );
      }
    } catch (err) {
      console.warn('got error: ', err);
      createNotification(
        NotificationType.ERROR,
        <div>Organisation registration error</div>,
        6000,
      );
    }
  };

  // console.log('props:', props);
  // console.log('params id:', id);

  return (
    <Glass className='my-4 py-8 px-4 min-h-[640px]'>
      <Grid
        container
        alignContent={'center'}
        alignItems={'center'}
        direction={'column'}
        spacing={8}
      >
        <Grid item xs={12}>
          <Typography variant={'h4'} renderAs={'h1'}>
            {"Confirm your organisation's registration email"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={onConfirmRegistrationEmail} color={'primary'}>
            <Typography>{'Confirm email'}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Glass>
  );
};

export { OrganisationConfirmation };
