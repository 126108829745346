import * as React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { NotificationType } from 'src/app/constants';

import { Button, Grid, LoadingIndicator } from 'src/app/components';
import { useAppContext, useAppStaticQueryConfig } from 'src/app/hooks';
import { FormRootContainer } from 'src/app/components/RHF';
// import { OrganisationSignupDocumentData } from 'src/app.organisation/types';

// LOCAL IMPORTS
import { useAdminContext } from 'src/admin/context';
import { BaseOrganisationDocument, OrganisationBankSettings } from 'src/admin/types';
import { navigate } from 'gatsby-link';
import { defaultFunctionsRegionOrCustomDomain } from 'src/lib/functions';
import { OrganisationPayoutForm } from './OrganisationPayoutForm';

import {
  defaultValues,
  // defaultValues__DEV,
  createDocumentResolver,
} from './organisationPayout.form.config';

const documentI18nBasePath = 'organisation:org-settings-payout.form';
type DocumentType = OrganisationBankSettings;

export interface UpdatePayoutInformationProps {}

export const UpdatePayoutInformation = (/* {}: UpdatePayoutInformationProps */) => {
  const { createNotification, firebase, isAppDebug, user, currentOrganisation } =
    useAppContext();
  const { document } = useAdminContext();
  const { env } = useAppStaticQueryConfig();

  const [isRequestingUpdate, setRequestingUpdate] = React.useState<boolean>(false);

  const orgDocument = document as any as BaseOrganisationDocument;

  const onSubmit = async (data: DocumentType) => {
    try {
      if (!firebase || !user?.uid || !currentOrganisation?.id) {
        return null;
      }
      setRequestingUpdate(true);

      // TODO: better validation of BIC / IBAN etc via regex
      const updatePayoutInformationData: OrganisationBankSettings & {
        organisationId: string;
      } = {
        ...data,
        organisationId: `${currentOrganisation.id}`,
      };

      const payoutInformationUpdate = httpsCallable(
        getFunctions(firebase, defaultFunctionsRegionOrCustomDomain(env)),
        'organisationFunction-payoutInformationUpdate',
      );

      const res = await payoutInformationUpdate(updatePayoutInformationData);

      // TODO: handle all error states...
      // if (res?.data?.code !== 'ok') {
      //   console.log('res.data.code: ', res?.data?.code || '');
      //   if (res?.data?.code === 'permission-denied') {
      //     // etc
      //   }
      //   // temp solution
      //   throw new Error(res?.data?.code, '')
      //   return;
      // }

      console.log('res: ', res);

      createNotification(
        NotificationType.SUCCESS,
        <div>Payout information change success</div>,
        5000,
      );
    } catch (err) {
      console.warn(err);
      createNotification(
        NotificationType.ERROR,
        <div>
          Payout information change error: {err?.message || ''}
          {/* <Localized dictKey={`${documentI18nBasePath}-errors.${err.message}`} /> */}
        </div>,
        5000,
      );
      return null;
    } finally {
      setRequestingUpdate(false);
    }
    return null;
  };

  const onSubmitWrapper = async (dataRaw: DocumentType) => {
    console.log('dataRaw: ', dataRaw);
    const data: DocumentType = {
      ...dataRaw,
    };
    onSubmit(data);
  };

  const result = orgDocument?.paymentSettings?.bank || null;
  const isSubmitDataValid = true;

  return !result ? null : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormRootContainer<DocumentType>
          className='px-4'
          defaultValues={{
            bankName: result?.bankName || defaultValues.bankName,
            bankAddressStreet:
              result?.bankAddressStreet || defaultValues.bankAddressStreet,
            bankAddressStreetNumber:
              result?.bankAddressStreetNumber || defaultValues.bankAddressStreetNumber,
            bankAddressZip: result?.bankAddressZip || defaultValues.bankAddressZip,
            bankAddressCity: result?.bankAddressCity || defaultValues.bankAddressCity,
            bankAddressCountry:
              result?.bankAddressCountry || defaultValues.bankAddressCountry,
            iban: result?.iban || defaultValues.iban,
            bic: result?.bic || defaultValues.bic,
          }}
          // isDebug={isDebug}
          resolver={createDocumentResolver}
          onSubmit={onSubmitWrapper}
          useFormProps={{ mode: 'onChange', reValidateMode: 'onChange' }}
          isDebug={isAppDebug}
          renderForm={({ formProps }) => {
            if (isRequestingUpdate) {
              return <LoadingIndicator />;
            }
            return (
              <OrganisationPayoutForm
                formProps={formProps}
                i18nBasePath={documentI18nBasePath}
                isSubmitDataValid={isSubmitDataValid}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container className='px-4'>
          <Grid item xs={12}>
            <Button fullWidth variant={'outlined'} onClick={() => navigate('../')}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
