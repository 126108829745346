import * as React from 'react';

import { Button, CenteredContainer, Divider, Grid, Typography } from 'src/app/components';

interface EmailNotificationManagementListUserProps {
  onButtonClick: () => void;
  buttonLabel: React.ReactNode;
  userName: string;
}

export const EmailNotificationManagementListUser = ({
  onButtonClick,
  buttonLabel,
  userName,
}: EmailNotificationManagementListUserProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <CenteredContainer centerV>
          <Typography variant={'h6'} renderAs={'p'} align={'left'}>
            {userName}
          </Typography>
        </CenteredContainer>
      </Grid>
      <Grid item xs={4}>
        <CenteredContainer>
          <Button variant={'outlined'} onClick={onButtonClick} fullWidth>
            {buttonLabel}
          </Button>
        </CenteredContainer>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
