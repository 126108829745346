import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { PrivateRoute, Seo } from 'src/app/components';

import { Router } from '@reach/router';

import { useAppContext } from 'src/app/hooks';
import { AdminLoginContainer } from 'src/admin/components';

import {
  OrganisationAdminApp,
  OrganisationConfirmationPage,
  OrganisationInvitationPage,
  OrganisationSignupPage,
  OrganisationTermsPage,
} from 'src/app.organisation/pages';

import { AdminType } from 'src/admin/constants';

const basePath = '/admin/partner';

const seo: SeoObject = {
  title: 'Organisation App Page',
};

const OrganisationAppPage = (props: AppProps) => {
  const { location, pageContext } = props;
  const { isLoggedIn } = useAppContext();
  return (
    <>
      {/* <CookieConsent /> // FIXME for all org sub pages + seo for alle subroutes if necessary */}
      <Seo location={location} pageContext={pageContext} seo={seo} />
      <Router basepath={basePath}>
        {/** NOTE: public routes - access possible without being logged in */}
        <OrganisationConfirmationPage {...props} path={'/confirmation/:id'} />
        <OrganisationInvitationPage {...props} path={'/invitation/:id'} />
        <OrganisationSignupPage {...props} path={'/signup/*'} />
        <OrganisationTermsPage {...props} path={'/terms-for-supplier'} />
        {isLoggedIn ? (
          <PrivateRoute
            default
            component={OrganisationAdminApp}
            // appSystem={'organisation'} // TODO: handle specialized permissions
            passProps={props}
          />
        ) : (
          <AdminLoginContainer
            default
            redirectAfterLoginTo={basePath}
            adminType={AdminType.ORGANISATION}
          />
        )}
      </Router>
    </>
  );
};

export default OrganisationAppPage;
