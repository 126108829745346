import * as React from 'react';
import { navigate } from 'gatsby';
import { where } from 'firebase/firestore';

import {
  ButtonTw,
  Localized,
  LocalizedErrorIndicator,
  LoadingIndicator,
} from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { FirestoreCollectionData, Pagination } from 'src/app/container';

import { ProductDocumentData } from 'src/app/types';

import { OrganisationProductListItem } from 'src/app.organisation/components';

// import { OrganisationAdminRootScreen } from 'src/app.organisation/constants';

// LOCAL IMPORTS
import { useAdminContext } from 'src/admin/context';
import { BaseOrganisationDocument } from 'src/admin/types';

// const documentI18nBasePath = 'organisation:document.activityProductDocument';
// const screenI18nBasePath = 'organisation:org-products';
// const collectionName = SourceCollectionName.PRODUCT;
// const collectionPath = (orgId: string) =>
//   getOrganisationSourceCollectionPath(orgId, collectionName);
// const routeBasepath = `/admin/partner/${collectionName}`;
// const routeBasepath = `/admin/partner/${'product'}`;

type DocumentType = ProductDocumentData;

// const MenuIcon = () => <SourceDataCityIcon />;
// const screenId = OrganisationAdminRootScreen.products;

export const ListScreen = () => {
  const { currentOrganisation } = useAppContext();
  const { document } = useAdminContext();

  const [currentDocumentCount, setCurrentDocumentCount] = React.useState<number | null>(
    null,
  );

  if (!currentOrganisation?.id || !document) {
    return null;
  }

  // const adminDoc = document as any as BaseOrganisationDocument; // TODO: use for limits?

  const organisationProductLimit = 64;
  const productPageLimit = 4;

  // const organisationProductLimit =
  //   currentOrganisation?.id ===
  //     '0c2jfqVvEGqhPLWEnbwF' /** stjepan.raic@directbox.com */ ||
  //   currentOrganisation?.id === 'Uk1p709U06HMKSYvlrFI' /** viva.croatia.com@gmail.com */
  //     ? 64
  //     : 16;

  return (
    <>
      <div className='py-4 flex flex-wrap md:flex-nowrap gap-4 items-center'>
        <div className='md:w-1/2'>
          <ButtonTw
            // disabled={currentDocumentCount >= organisationProductLimit}
            onClick={() => navigate('/admin/partner/product/create')}
            size={'lg'}
            rounded
            variant={'accent'}
          >
            {/* Create a new product ({currentDocumentCount || 0}/{organisationProductLimit} // TODO: make currently no sense since the product creation limit is applied productCatType wise
            ) */}
            <Localized
              dictKey={
                'organisation:org-products.product.action.create-product-page-link.label'
              }
            />
          </ButtonTw>
        </div>
        {/* <Grid item xs={6}>
          <CenteredContainer>
            <Typography variant={'h6'} renderAs={'p'}>
              Create up to {organisationProductLimit} Products // TODO: make currently no sense since the product creation limit is applied productCatType wise
            </Typography>
          </CenteredContainer>
        </Grid> */}
        <div className='md:w-1/2'>
          <p className='text-p2'>
            <Localized
              dictKey={'organisation:org-products.product.product-create-info'}
            />
          </p>
        </div>
      </div>

      <FirestoreCollectionData<DocumentType>
        collectionPath={`organisation/${currentOrganisation.id}/${'product_source'}`} // FIXME:
        queryConstraints={[where('meta.status', '!=', '__deleted')]}
        queryLimit={organisationProductLimit}
        queryOrderBy={'meta.status'}
        queryOrderDirection={'asc'}
        noIdField={'TODO'} // FIXME
        renderElements={({ data: tableData, status }) => {
          if (status === 'loading') {
            return <LoadingIndicator />;
          }
          if (status === 'error') {
            return (
              <LocalizedErrorIndicator
                i18nContext={'organisation'}
                i18nErrorCode={'LOADING_COLLECTION'}
                i18nErrorAreaCode={'product_source'}
              />
            );
          }
          // if (
          //   currentDocumentCount === null &&
          //   status === 'success' &&
          //   tableData.length
          // ) {
          //   setCurrentDocumentCount(tableData.length);
          // }
          return status !== 'success' ? null : (
            <div className='py-2'>
              <h3 className='text-p1 font-semibold py-2'>
                <Localized
                  dictKey={'organisation:org-products.product.product-list-title'}
                />
              </h3>
              <Pagination<DocumentType>
                data={tableData}
                className='py-2'
                hideControlsTop
                pageSize={productPageLimit}
                renderElements={({ data: paginatedData }) => (
                  <div className='flex flex-col gap-y-2 md:gap-y-4'>
                    {tableData?.length
                      ? paginatedData.map((product) => (
                          <div key={product.id}>
                            <OrganisationProductListItem product={product} />
                          </div>
                        ))
                      : null}
                  </div>
                )}
              />
            </div>
          );
        }}
      />

    </>
  );
};
