import * as React from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CommunicationIcon from '@material-ui/icons/Forum';
import DocumentsIcon from '@material-ui/icons/NoteAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
// import CreateIcon from '@material-ui/icons/Create';
// import PostAddIcon from '@material-ui/icons/PostAdd';
// import PermMediaIcon from '@material-ui/icons/PermMedia';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { AdminAccount } from 'src/admin/components';
import { AdminScreenNavigationElement, AdminViewLayout } from 'src/admin/constants';
import { ScreenNavigationConfigData } from 'src/admin/types';

import { OrganisationAdminRootScreen } from 'src/app.organisation/constants';
import { OrganisationAdminScreenConfig } from 'src/app.organisation/types';
import { DocumentPreviewScreenRouter } from '../organisationDocumentPreview';
import { OrganisationOrderDetails } from './pages/organisationOrders/OrganisationOrderDetails';
import {
  OrganisationCommunicationScreen,
  OrganisationDocumentsScreen,
  OrganisationDashboardScreen,
  // OrganisationReportScreen,
  // OrganisationMediaScreen,
  OrganisationOrderScreen,
  // OrganisationCustomerScreen,
  ProductDocumentScreen,
  // ProductCreateOptionsScreen,
  // ProductManagementScreen,
  // ProductSettingsScreen,
  OrganisationUserScreen,
  OrganisationSettingsScreen,
} from './pages';

export const organisationAdminScreens: OrganisationAdminScreenConfig[] = [
  {
    id: OrganisationAdminRootScreen.preview,
    content: DocumentPreviewScreenRouter,
    layoutType: AdminViewLayout.fullview,
    matchPath: '/preview',
  },
  {
    id: OrganisationAdminRootScreen.account,
    content: AdminAccount,
    layoutType: AdminViewLayout.default,
    matchPath: '/account',
  },
  // CONFIGURATION START
  {
    id: OrganisationAdminRootScreen.organisation,
    content: OrganisationDashboardScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/',
  },
  {
    id: OrganisationAdminRootScreen.communication,
    content: OrganisationCommunicationScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/communication',
  },
  {
    id: OrganisationAdminRootScreen.orders,
    content: OrganisationOrderDetails,
    layoutType: AdminViewLayout.default,
    matchPath: '/orders/:orderId',
  },
  {
    id: OrganisationAdminRootScreen.orders,
    content: OrganisationOrderScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/orders',
  },
  {
    id: OrganisationAdminRootScreen.productGeneration,
    content: ProductDocumentScreen,
    layoutType: AdminViewLayout.fullview,
    matchPath: '/product',
  },
  {
    id: OrganisationAdminRootScreen.user,
    content: OrganisationUserScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/user',
  },
  {
    id: OrganisationAdminRootScreen.settings,
    content: OrganisationSettingsScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/settings',
  },
  {
    id: OrganisationAdminRootScreen.documents,
    content: OrganisationDocumentsScreen,
    layoutType: AdminViewLayout.default,
    matchPath: '/documents',
  },
  // {
  //   id: OrganisationAdminRootScreen.reports,
  //   content: OrganisationReportScreen,
  //   layoutType: AdminViewLayout.default,
  //   matchPath: '/reports',
  // },
  // {
  //   id: OrganisationAdminRootScreen.media,
  //   content: OrganisationMediaScreen,
  //   layoutType: AdminViewLayout.default,
  //   matchPath: '/media',
  // },
  // {
  //   id: OrganisationAdminRootScreen.customers,
  //   content: OrganisationCustomerScreen,
  //   layoutType: AdminViewLayout.default,
  //   matchPath: '/customers',
  // },
  // {
  //   id: OrganisationAdminRootScreen.productManagement,
  //   content: ProductDocumentScreen,
  //   layoutType: AdminViewLayout.fullview,
  //   matchPath: '/products/list',
  // },
  // {
  //   id: OrganisationAdminRootScreen.productSettings,
  //   content: ProductDocumentScreen,
  //   layoutType: AdminViewLayout.fullview,
  //   matchPath: '/products/settings',
  // },

  // {
  //   id: OrganisationAdminRootScreen.productGeneration,
  //   content: ProductCreateOptionsScreen,
  //   layoutType: AdminViewLayout.fullview,
  //   matchPath: '/products/create',
  // },
  // {
  //   id: OrganisationAdminRootScreen.productManagement,
  //   content: ProductManagementScreen,
  //   layoutType: AdminViewLayout.fullview,
  //   matchPath: '/products/list',
  // },
  // {
  //   id: OrganisationAdminRootScreen.productSettings,
  //   content: ProductSettingsScreen,
  //   layoutType: AdminViewLayout.fullview,
  //   matchPath: '/products/settings',
  // },
];

export const organisationAdminScreenNavigationConfig: ScreenNavigationConfigData<OrganisationAdminRootScreen>[] =
  [
    {
      id: OrganisationAdminRootScreen.communication,
      screen: OrganisationAdminRootScreen.communication,
      type: AdminScreenNavigationElement.button,
      icon: <CommunicationIcon />,
      matchPath: '/communication',
    },
    {
      id: OrganisationAdminRootScreen.orders,
      screen: OrganisationAdminRootScreen.orders,
      type: AdminScreenNavigationElement.button,
      icon: <ListAltIcon />,
      matchPath: '/orders',
    },
    {
      id: OrganisationAdminRootScreen.productManagement,
      screen: OrganisationAdminRootScreen.productManagement,
      type: AdminScreenNavigationElement.button,
      icon: <EventIcon />,
      matchPath: '/product',
    },
    /* {
      id: OrganisationAdminRootScreen.user,
      screen: OrganisationAdminRootScreen.user,
      type: AdminScreenNavigationElement.button,
      icon: <PeopleIcon />,
      matchPath: '/user',
    }, */
    {
      id: OrganisationAdminRootScreen.settings,
      screen: OrganisationAdminRootScreen.settings,
      type: AdminScreenNavigationElement.button,
      icon: <SettingsIcon />,
      matchPath: '/settings',
    },
    {
      id: OrganisationAdminRootScreen.documents,
      screen: OrganisationAdminRootScreen.documents,
      type: AdminScreenNavigationElement.button,
      icon: <DocumentsIcon />,
      matchPath: '/documents',
    },
    // TODO: commented out for v1
    // {
    //   id: OrganisationAdminRootScreen.organisation,
    //   screen: OrganisationAdminRootScreen.organisation,
    //   type: AdminScreenNavigationElement.button,
    //   icon: <AssessmentIcon />,
    // matchPath: '/',
    // },
    // {
    //   id: OrganisationAdminRootScreen.reports,
    //   screen: OrganisationAdminRootScreen.reports,
    //   type: AdminScreenNavigationElement.button,
    //   icon: <AssessmentIcon />,
    //   matchPath: '/reports',
    // },
    // {
    //   id: OrganisationAdminRootScreen.media,
    //   screen: OrganisationAdminRootScreen.media,
    //   type: AdminScreenNavigationElement.button,
    //   icon: <PermMediaIcon />,
    //   matchPath: '/media',
    // },
    // {
    //   id: OrganisationAdminRootScreen.customers,
    //   screen: OrganisationAdminRootScreen.customers,
    //   type: AdminScreenNavigationElement.button,
    //   icon: <AssessmentIcon />,
    // },
    // {
    //   id: OrganisationAdminRootScreen.products,
    //   type: AdminScreenNavigationElement.expandableContainer,
    //   icon: <PostAddIcon />,
    //   matchPath: '/products',
    //   options: [
    //     // {
    //     //   id: OrganisationAdminRootScreen.productGeneration,
    //     //   screen: OrganisationAdminRootScreen.productGeneration,
    //     //   type: AdminScreenNavigationElement.button,
    //     //   icon: <CreateIcon />,
    //     //   matchPath: '/products/create',
    //     // },
    //     {
    //       id: OrganisationAdminRootScreen.productManagement,
    //       screen: OrganisationAdminRootScreen.productManagement,
    //       type: AdminScreenNavigationElement.button,
    //       icon: <EventIcon />,
    //       matchPath: '/products',
    //     },
    //     // {
    //     //   id: OrganisationAdminRootScreen.productSettings,
    //     //   screen: OrganisationAdminRootScreen.productSettings,
    //     //   type: AdminScreenNavigationElement.button,
    //     //   icon: <AssessmentIcon />,
    //     //   matchPath: '/products/settings',
    //     // },
    //   ],
    // },
  ];

// TODO: needed for org user account with own side bar nav menu... (reintegrade 14.02.2020)
export const organisationAdminAccountScreenNavigationConfig: ScreenNavigationConfigData<any>[] =
  [
    {
      id: OrganisationAdminRootScreen.organisation,
      screen: OrganisationAdminRootScreen.organisation,
      type: AdminScreenNavigationElement.button,
      icon: <AssessmentIcon />,
      matchPath: '/',
    },
  ];
