import * as React from 'react';
import { Router } from '@reach/router';

import {
  Container,
  ErrorBoundary,
  ErrorFallback,
  errorHandler,
  LocalizedErrorIndicator,
  LoadingIndicator,
  PrivateRoute,
  Typography,
} from 'src/app/components';
import { AppFeature } from 'src/app/constants';
import { useAppContext, useAppFeatures } from 'src/app/hooks';
import { FirestoreDocData } from 'src/app/container';

import { AdminLayoutContainer } from 'src/admin/components';
import { AdminType } from 'src/admin/constants';
import { AdminContextProvider } from 'src/admin/context';

import { OrganisationDocumentNotifications } from 'src/app.organisation/components';
import { OrganisationAdminRootScreen } from '../../constants/organisation.constants';
import { OrganisationAdminScreen } from '../../types/organisation.types';

import {
  organisationAdminScreens,
  organisationAdminScreenNavigationConfig,
} from './OrganisationAdminApp.config';

const initialOrganisationAdminScreen = OrganisationAdminRootScreen.products; // OrganisationAdminRootScreen.organisation
const i18nNS = 'organisation';
const basePath = '/admin/partner';

export const OrganisationAdminApp = () => {
  const { currentOrganisation, isLoggedIn, isEmailVerified, isSystemUser } =
    useAppContext();
  const { hasFeature: hasOrganisationAreaFeature } = useAppFeatures(
    AppFeature.ORGANISATION_AREA,
  );
  const [currentScreen, setCurrentScreen] = React.useState<OrganisationAdminScreen>(
    initialOrganisationAdminScreen,
  );

  if (
    !isLoggedIn ||
    !isEmailVerified ||
    !(!currentOrganisation?.id || !isSystemUser) || // NOTE: hack for system admins for product preview
    !(!hasOrganisationAreaFeature || !isSystemUser) || // NOTE: hack for system admins for product preview
    typeof window === 'undefined' ||
    !window.location.pathname.startsWith(basePath)
  ) {
    if (!isEmailVerified) {
      return (
        <Container className='p-10'>
          <Typography align={'center'} variant={'h4'} renderAs={'p'}>
            You have to verify your email first, in order to be able to use the partner
            area
          </Typography>
        </Container>
      );
    }
    return null;
  }

  // for preview routes
  const hideAdminLayout = window.location.pathname.startsWith(`${basePath}/preview`);

  return (
    <>
      {hideAdminLayout ? ( // extra route for previews atm (no extra context wrapper)
        <Router basepath={basePath}>
          {organisationAdminScreens.map((screen) =>
            screen.matchPath ? (
              <PrivateRoute
                key={screen.id}
                path={`${screen.matchPath}/*`}
                component={screen.content as React.FC}
                // appSystem={'organisation'} // TODO: handle specialized permissions
                // passProps={} // if necessary...
              />
            ) : null,
          )}
        </Router>
      ) : (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          errorHandler={errorHandler}
          onReset={() =>
            typeof window !== 'undefined' ? window.location.reload() : null
          }
        >
          <FirestoreDocData
            useSubscription // NOTE: useSubscription
            documentPath={`${AdminType.ORGANISATION.toString()}/${
              currentOrganisation?.id
            }`}
            renderElements={({ data: organisationDocument, status }) => {
              if (status === 'loading') {
                return <LoadingIndicator />;
              }
              if (status === 'error') {
                return (
                  <LocalizedErrorIndicator
                    i18nContext={'organisation'}
                    i18nErrorCode={'LOADING_DOCUMENT'}
                    i18nErrorAreaCode={'organisation'}
                  />
                );
              }
              return status !== 'success' ? null : (
                <AdminContextProvider document={organisationDocument} role="partner">
                  <AdminLayoutContainer<OrganisationAdminScreen>
                    basePath={basePath}
                    currentScreen={currentScreen}
                    i18nNS={i18nNS}
                    screenNavigationConfig={organisationAdminScreenNavigationConfig}
                    setCurrentScreen={setCurrentScreen}
                  >
                    {typeof window !== 'undefined' && organisationDocument ? (
                      <OrganisationDocumentNotifications />
                    ) : null}

                    <Router basepath={basePath}>
                      {organisationAdminScreens.map((screen) =>
                        screen.matchPath ? (
                          <PrivateRoute
                            key={screen.id}
                            path={`${screen.matchPath}/*`}
                            component={screen.content as React.FC}
                            // appSystem={'organisation'} // TODO: handle specialized permissions
                            // passProps={} // if necessary...
                          />
                        ) : null,
                      )}
                    </Router>
                  </AdminLayoutContainer>
                </AdminContextProvider>
              );
            }}
          />
        </ErrorBoundary>
      )}
    </>
  );
};
