import * as React from 'react';

import {
  Button,
  BooleanSwitch,
  Divider,
  Grid,
  Typography,
  StringPropertyRow,
  Localized,
} from 'src/app/components';
import { navigate } from 'gatsby';
import { useAdminContext } from 'src/admin/context';
import {
  BaseOrganisationDocument,
  PayoutInterval,
  AdminScreenProps,
} from 'src/admin/types';

import { AdminMenuContainer } from 'src/admin/components';
// import { formatFirebaseTimestamp } from 'src/lib/date';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
const i18nNS = 'organisation';
const screenId = 'org-settings-payout';
const i18nPath = `${i18nNS}:${screenId}`;
const contentI18nPath = `${i18nNS}:${screenId}.content`;
const formI18nPath = `${i18nNS}:${screenId}.form`;

export interface OrganisationPayoutSettingsProps {}

export const OrganisationPayoutSettings = (
  { icon }: AdminScreenProps /* {}: OrganisationPayoutSettingsProps */,
) => {
  const { document } = useAdminContext();
  const orgDocument = document as any as BaseOrganisationDocument;
  const [payoutDate, setPayoutDate] = React.useState<Date | null>(null);

  React.useEffect(() => {
    const calculatePayoutDate = () => {
      if (!orgDocument?.paymentSettings?.payoutBaseDate) {
        return;
      }
      const now = new Date();
      const baseDate = orgDocument.paymentSettings.payoutBaseDate.toDate();
      const baseDateDate = dayjs(baseDate).date(); // 1-31
      const payoutDay = dayjs(now).set('date', baseDateDate);
      const nextMonthPayoutDay = dayjs(payoutDay).add(1, 'month');
      const diffTime = dayjs(dayjs(payoutDay)).diff(dayjs(now));

      if (diffTime < 0) {
        // const diffTime2 = dayjs(dayjs(newMonthPayoutDay)).diff(dayjs(now));
        // console.log('Payout will be next month...');
        // console.log('diffTime2: ', diffTime2);
        // console.log(
        //   'diffTime2 Duration: ',
        //   dayjs.duration({ seconds: diffTime2 }).asDays(),
        // );
        setPayoutDate(nextMonthPayoutDay.toDate());
      } else {
        // console.log('diffTime: ', diffTime);
        // console.log('diffTime Duration: ', dayjs.duration({ seconds: diffTime }).asDays());

        setPayoutDate(payoutDay.toDate());
      }
    };

    if (orgDocument && !payoutDate) {
      calculatePayoutDate();
    }
  }, [orgDocument, payoutDate]);

  // const onChangePayoutInterval = () => {}; // TODO

  if (!orgDocument) {
    return null;
  }

  // const {
  //   isOrganisationDocumentsValid,
  //   isOrganisationPaymentDataValid,
  //   isOrganisationUnlocked,
  // } = orgDocument;

  // const showPayoutDate = !!(
  //   isOrganisationDocumentsValid &&
  //   isOrganisationPaymentDataValid &&
  //   isOrganisationUnlocked
  // );

  const showPayoutDate = true;

  return (
    <AdminMenuContainer icon={icon} i18nNS={i18nNS} screenId={screenId}>
      <Grid container spacing={4} className='p-2'>
        <Grid item xs={12}>
          <BooleanSwitch
            description={
              <Localized dictKey={`${contentI18nPath}.payout-interval.description`} />
            }
            title={<Localized dictKey={`${contentI18nPath}.payout-interval.title`} />}
            label={<Localized dictKey={`${contentI18nPath}.payout-interval.label`} />}
            value={orgDocument.paymentSettings.payoutInterval === PayoutInterval.MONTHLY}
            disabled // always disabled passive indicator
            onSwitch={() => null}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <BooleanSwitch
            description={
              <Localized dictKey={`${contentI18nPath}.custom-interval.description`} />
            }
            title={<Localized dictKey={`${contentI18nPath}.custom-interval.title`} />}
            label={<Localized dictKey={`${contentI18nPath}.custom-interval.label`} />}
            value={orgDocument.paymentSettings.payoutInterval !== PayoutInterval.MONTHLY}
            disabled // always disabled passive indicator
            onSwitch={() => null}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {showPayoutDate ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography align={'left'} variant={'h5'} renderAs={'h2'}>
                  <Localized dictKey={`${contentI18nPath}.payout-date.label`} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align={'right'} variant={'h5'} renderAs={'h2'}>
                  {payoutDate ? payoutDate.toDateString() : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} renderAs={'h2'}>
            <Localized dictKey={`${contentI18nPath}.current-payout-information.title`} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className='px-2 py-2'>
            <Grid item xs={12}>
              <StringPropertyRow
                title={<Localized dictKey={`${formI18nPath}.iban.field.name`} />}
                value={orgDocument?.paymentSettings?.bank?.iban || ''}
                underlined
              />
              <StringPropertyRow
                title={<Localized dictKey={`${formI18nPath}.bic.field.name`} />}
                value={orgDocument?.paymentSettings?.bank?.bic || ''}
                className='pb-2'
              />
              <StringPropertyRow
                title={<Localized dictKey={`${formI18nPath}.bankName.field.name`} />}
                value={orgDocument?.paymentSettings?.bank?.bankName || ''}
                underlined
              />
              <StringPropertyRow
                title={
                  <Localized
                    dictKey={`${contentI18nPath}.current-payout-information.bank-address`}
                  />
                }
                value={`${orgDocument?.paymentSettings?.bank?.bankAddressStreet || ''} ${
                  orgDocument?.paymentSettings?.bank?.bankAddressStreetNumber || ''
                }`.trim()}
                underlined
              />
              <StringPropertyRow
                title={
                  <Localized dictKey={`${formI18nPath}.bankAddressCity.field.name`} />
                }
                value={orgDocument?.paymentSettings?.bank?.bankAddressCity || ''}
                underlined
              />
              <StringPropertyRow
                title={
                  <Localized dictKey={`${formI18nPath}.bankAddressCountry.field.name`} />
                }
                value={orgDocument?.paymentSettings?.bank?.bankAddressCountry || ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant={'outlined'} onClick={() => navigate('update')}>
            <Localized dictKey={`${i18nPath}.action.update-payment-information.label`} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant={'outlined'} onClick={() => navigate('../')}>
            <Localized dictKey={`${i18nPath}.action.navigate-back.label`} />
          </Button>
        </Grid>
      </Grid>
    </AdminMenuContainer>
  );
};
