import * as React from 'react';
import { Router } from '@reach/router';

import { PrivateRoute } from 'src/app/components';

import { GatsbyNodeType, SourceCollectionName } from 'src/app/constants';

import { useAppContext } from 'src/app/hooks';

import { DocumentPreviewScreen } from './DocumentPreviewScreen';

// TODO: create common config for DocumentPreviewScreenRouter & DocumentListContainer
/** PREVIEW SWITCH  // TODO move into cfg */
const previewRoutesConfig = [
  {
    path: '/product/:id',
    props: {
      collectionName: SourceCollectionName.PRODUCT,
      nodeType: GatsbyNodeType.PRODUCT,
    },
  },
];

const routeBasepath = '/admin/partner/preview';
export const DocumentPreviewScreenRouter = () => {
  const { currentOrganisation, isSystemUser } = useAppContext();
  return (
    <Router basepath={routeBasepath}>
      {((currentOrganisation?.id || isSystemUser) && // NOTE: hack for system admin user preview reuse feature
        previewRoutesConfig?.map((cfg) => (
          <PrivateRoute
            key={cfg.path}
            path={cfg.path}
            component={DocumentPreviewScreen}
            passProps={cfg.props}
          />
        ))) ||
        null}
    </Router>
  );
};
