import * as React from 'react';
import { doc, getFirestore, setDoc, where } from 'firebase/firestore';

import { Checkbox, TextField } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CollectionPermissions, FirebaseTimestamp } from 'src/app/types';
import { PermissionType, NotificationType } from 'src/app/constants';
import { FirestoreCollectionData } from 'src/app/container';
import {
  Accordion,
  Button,
  Divider,
  Grid,
  LoadingIndicator,
  Typography,
} from 'src/app/components';
// import { ScreenTitleLocalized } from 'src/app.organisation/components';
import { useAppContext } from 'src/app/hooks';

import { formatFirebaseTimestamp } from 'src/lib/date';
// import { useFirestore } from 'reactfire';

import { getOrganisationUserDisplayName } from 'src/app.organisation/lib';
import { useAdminContext } from 'src/admin/context';
import { BaseOrganisationDocument } from 'src/admin/types';

const Permission = (props: {
  collection: string;
  permissionType: PermissionType;
  user: UserDocumentData;
}) => {
  const { collection, permissionType, user } = props;
  const { currentOrganisation, createNotification, firebase } = useAppContext();
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const collectionBeforeValues = {
      ...user.collection[collection],
    };

    if (!currentOrganisation?.id || !user?.id) {
      return null;
    }

    try {
      const store = getFirestore(firebase);
      const docRef = doc(store, `organisation/${currentOrganisation.id}/user/${user.id}`);
      await setDoc(
        docRef,
        {
          collection: {
            [collection]: {
              ...collectionBeforeValues,
              [permissionType]: e.target.checked,
            },
          },
        },
        { merge: true },
      );
      createNotification(NotificationType.SUCCESS, 'Document successfully updated', 3000);
    } catch (err) {
      console.warn(err);
    }
    return null;
  };
  return user?.collection?.[collection] ? (
    <Grid container style={{ paddingLeft: '32px' }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={user.collection[collection][permissionType] || false}
              onChange={onChange}
              name='checkedA'
            />
          }
          label={permissionType}
          labelPlacement={'end'}
        />
      </Grid>
    </Grid>
  ) : null;
};

const UserCollectionPermissions = (props: {
  collection: string;
  user: UserDocumentData;
}) => {
  const { collection, user } = props;
  const permissionTypes: PermissionType[] = [
    PermissionType.CREATE,
    PermissionType.DELETE,
    PermissionType.GET,
    PermissionType.LIST,
    PermissionType.UPDATE,
  ];

  return (
    <Grid container style={{ marginTop: '16px' }}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>
          <Typography paragraph variant={'h6'} renderAs={'h4'}>
            <em>{collection}</em>
          </Typography>
        </FormLabel>
        <FormGroup aria-label='position' row>
          <Grid container>
            {permissionTypes.map((pType) => (
              <Grid key={pType} item xs={12} md={2}>
                <Permission collection={collection} permissionType={pType} user={user} />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

interface UserDocumentData extends Record<string, unknown> {
  collection: { [key: string]: CollectionPermissions };
  email: string;
  createdAt: FirebaseTimestamp;
  id: string;
  roles: string[]; // TODO: constants owner/user atm
  v: string;
}

const Summary = (props: { user: UserDocumentData }) => {
  const { user } = props;
  const { document } = useAdminContext();
  const inviteTime = formatFirebaseTimestamp(user?.meta?.createdAt || user?.createdAt || '');

  if(!document) {
    return null;
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography
          align={'left'}
          style={{ wordWrap: 'break-word', flexWrap: 'wrap', overflowWrap: 'anywhere' }}
        >
          {getOrganisationUserDisplayName(document as any as BaseOrganisationDocument, user.id) || user?.email || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Typography
          align={'left'}
          style={{ wordWrap: 'break-word', flexWrap: 'wrap', overflowWrap: 'anywhere' }}
        >
          {user?.role?.toString() || user?.roles?.toString() || ''}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          align={'right'}
          style={{ wordWrap: 'break-word', flexWrap: 'wrap', overflowWrap: 'anywhere' }}
        >
          {user?.meta?.createdAt ? 'Invited on ' : ''}
          {user?.createdAt ? 'Joined on ' : ''}
          {inviteTime || ''}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} md={3}>
        <Typography
          align={'center'}
          style={{ wordWrap: 'break-word', flexWrap: 'wrap', overflowWrap: 'anywhere' }}
        >
          {user.id || ''}
        </Typography>
      </Grid> */}
    </>
  );
};

const permissionCollections = [
  'order',
  'product_group',
  'image',
  'product_source',
  'report',
  'user',
  'user_invitation',
];

// TODO: wrap in transactions to handle concurrency issues with other userAdmins?

const OrganisationUserList = (props: {
  collectionType: 'user' | 'user_invitation';
  organisationId: string;
  permissionController?: boolean;
}) => {
  const { collectionType, organisationId, permissionController } = props;

  if (!organisationId || !collectionType) {
    return null;
  }

  let collectionPath = `organisation/${organisationId}/${collectionType}`

  if(collectionType === 'user_invitation') {
    collectionPath = `organisation_invitation`;
  }

  return (
    <FirestoreCollectionData<UserDocumentData>
      collectionPath={collectionPath}
      queryConstraints={collectionType === 'user_invitation' ? [where('organisationId', '==', organisationId), where('signedUpAt', '==', null)] : []}
      renderElements={({ data: organisationUsers, status }) => {
        if (status === 'loading') {
          return <LoadingIndicator />;
        }
        if (status === 'error') {
          return <>{'Error'}</>;
        }
        return status !== 'success' ? null : (
          <Grid container spacing={2}>
            {organisationUsers?.length > 0
              ? organisationUsers.map((user) => {
                  return (
                    <Grid key={user.email} item xs={12}>
                      <Grid container>
                        {permissionController ? (
                          <Accordion
                            style={{ borderWidth: '0', width: '100%' }}
                            expandIcon={<ExpandMoreIcon />}
                            summary={
                              <Grid container spacing={2} className='p-2'>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Summary user={user} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          >
                            <Grid container spacing={2} className='p-2'>
                              {/* <Grid item xs={12}>
                                <Typography variant={'h6'} renderAs={'h3'} paragraph>
                                  Organisation collection permissions:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  {permissionCollections.map((pCol) => (
                                    <Grid key={pCol} item xs={12}>
                                      <UserCollectionPermissions
                                        collection={pCol}
                                        user={user}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid> */}
                              <Grid item xs={12}>
                                <div className='px-2'>
                                  <Typography>Email: {user.email || 'N/A'}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className='px-2'>
                                  <Typography>Organisation role: {user.roles?.toString() || 'N/A'}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className='px-2'>
                                  <Typography>User Id: {user.id || 'N/A'}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <Button color={'primary'} fullWidth disabled>
                                  <Typography>Change user role</Typography>
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button color={'primary'} fullWidth disabled>
                                  <Typography>Delete user</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Accordion>
                        ) : (
                          <Accordion
                            nonVisuallyDisabled
                            style={{ borderWidth: '0', width: '100%' }}
                            expandIcon={<ExpandMoreIcon />}
                            summary={
                              <Grid container spacing={2} className='px-2'>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Summary user={user} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          >
                            {''}
                          </Accordion>
                        )}
                      </Grid>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        );
      }}
    />
  );
};

export { OrganisationUserList };
