import {
  OrganisationTurnoverAmount,
  ProductCategoryType,
  SocialMediaType,
} from 'src/app/constants';
import { FirebaseTimestamp } from 'src/app/types';

export enum PayoutInterval {
  MONTHLY = 'monthly',
  EVERY_TWO_WEEKS = 'every_two_weeks',
}

export interface BaseOrganisationDocument extends Record<string, any> {
  customPaymentIntervals: PayoutInterval[];
  email: string;
  emailConfirmedAt: FirebaseTimestamp;
  id: string;
  isOrganisationDocumentsValid: boolean;
  isOrganisationUnlocked: boolean;
  isOrganisationDisabled: boolean;
  isOrganisationPaymentDataValid: boolean;
  isOrganisationPaymentDataSubmitted: boolean;
  meta: {
    createdAt: FirebaseTimestamp;
    systemIds: string[];
  };

  // NOTE: array of org user ids
  notificationSettings: OrganisationNotificationSettings;

  organisation: OrganisationAdditionalInformationData & OrganisationLocationData;
  organisationData: {
    products: {};
    user: {
      userCount: number;
      users: { id: string; displayName: string }[];
    };
  }; // TODO: define
  organisationOwner: OrganisationOwnerData;
  organisationSettings: {}; // TODO: define
  organisationUploadDocuments: OrganisationUploadDocuments;
  paymentSettings: OrganisationPaymentSettings;
  productCategoryTypeConfig: OrganisationProductCategoryTypeConfig[];
}

export interface OrganisationProductCategoryTypeConfig {
  id: ProductCategoryType;
  isEnabled: boolean;
  productLimit: number;
}

export interface OrganisationPaymentSettings {
  bank: OrganisationBankSettings;
  payoutBaseDate: FirebaseTimestamp; // TODO: create initial value logic on BE
  payoutInterval: PayoutInterval;
}

export interface OrganisationBankSettings {
  bankName: string;
  bankAddressStreet: string;
  bankAddressStreetNumber: string;
  bankAddressZip: string;
  bankAddressCity: string;
  bankAddressCountry: string;
  iban: string;
  bic: string;
  updatedAt?: FirebaseTimestamp; // will be updated/injected in BE
}

export interface OrganisationUploadDocuments extends Record<string, string> {
  organisationInsuranceDocument: string;
  organisationLicenceDocument: string;
  organisationRegistrationDocument: string;
}

export interface OrganisationNotificationSettings extends Record<string, string[]> {
  cancelMessage: string[];
  generalMessage: string[];
  orderMessage: string[];
  privateReservation: string[];
  referenceMessage: string[];
  systemMessage: string[];
}

export interface SocialMediaInformation {
  type: SocialMediaType;
  value: string;
}

export interface OrganisationAdditionalInformationData {
  // activitiesDescription: string;
  // organisationDescription: string;
  website: string;
  socialMedia: SocialMediaInformation[];
  localeId: string;
  organisationEmployeeCount: number;
  organisationFoundedDate: Date;
  organisationYearlyGrossTurnover: OrganisationTurnoverAmount;
  organisationCategoryIds: string[];
  organisationCategoryTypeIds: string[];
}

export interface OrganisationLocationData {
  organisationAddressStreet: string;
  organisationAddressStreet2?: string;
  organisationAddressStreetNumber: string;
  organisationCity: string;
  organisationCountry: string;
  organisationName2?: string;
  organisationName: string;
  organisationPostOfficeBox?: string;
  organisationZipCode: string;
  organisationFoundedDate:string
}

export interface OrganisationOwnerData {
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerPassword: string;
  ownerPasswordConfirmation: string;
  ownerPhone: string;
}

// enum OrganisationEmailNotificationType {
//   CANCEL_MESSAGE = 'cancelMessage',
//   GENERAL_MESSAGE = 'generalMessage',
//   ORDER_MESSAGE = 'orderMessage',
//   PRIVATE_RESERVATION = 'privateReservation',
//   REFERENCE_MESSAGE = 'referenceMessage',
//   SYSTEM_MESSAGE = 'systemMessage',
// }
