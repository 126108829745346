import * as React from 'react';

import { Router } from '@reach/router';

import { Route } from 'src/app/components';
import {
  ProductEventsContainer,
  ProductEventsContainerProps,
} from './ProductEventsContainer';

import { ProductEventRouter, ProductEventRouterProps } from './productEvent';

export interface ProductEventsRouterProps {
  id?: string; // ReachRouter prop
  routerBasePath: string;
}

export const ProductEventsRouter = ({
  id: productId,
  routerBasePath,
}: ProductEventsRouterProps) => {
  // const basepath = `${routerBasePath}/${productId}`;

  // console.log('routerBasePath: ', routerBasePath);
  // console.log('productId: ', productId);

  return !productId ? null : (
    <Router basepath={routerBasePath}>
      <Route<ProductEventRouterProps>
        path={'/:productId/:eventId/*'}
        component={ProductEventRouter}
        passProps={{ routerBasePath: `${routerBasePath}/${productId}` }}
      />
      <Route<ProductEventsContainerProps>
        path={'/:id'}
        component={ProductEventsContainer}
        // passProps={{ productId }}
      />
    </Router>
  );
};
