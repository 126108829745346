import * as React from 'react';
import { navigate } from 'gatsby';

import { Button, Grid, Localized } from 'src/app/components';
import { useAdminContext } from 'src/admin/context';
import { BaseOrganisationDocument, AdminScreenProps } from 'src/admin/types';
import { AdminMenuContainer } from 'src/admin/components';
import { EmailNotificationManagement } from './EmailNotificationManagement';

const emailPoolIds = [
  'cancelMessage',
  'generalMessage',
  'orderMessage',
  'privateReservation',
  'referenceMessage',
  'systemMessage',
];

export interface OrganisationEmailSettingsProps extends AdminScreenProps {}

const i18nNS = 'organisation';
const screenId = 'org-settings';
const i18nPath = `${i18nNS}:${screenId}`;
const settingsEmailI18nPath = `${i18nPath}-email`;

export const OrganisationEmailSettings = ({ icon }: OrganisationEmailSettingsProps) => {
  const { document } = useAdminContext();

  if (!document?.notificationSettings) {
    return null;
  }

  const { notificationSettings } = document as any as BaseOrganisationDocument;

  return (
    <AdminMenuContainer icon={icon} i18nNS={i18nNS} screenId={`${screenId}-email`}>
      <Grid container spacing={8} className='p-2'>
        {emailPoolIds?.length
          ? emailPoolIds.map((id) => {
              return (
                <Grid item xs={12} key={id}>
                  {notificationSettings?.[id] ? (
                    <EmailNotificationManagement
                      notificationType={id}
                      notificationSettings={notificationSettings}
                    />
                  ) : null}
                </Grid>
              );
            })
          : null}
        <Grid item xs={12}>
          <Grid container className='px-4'>
            <Grid item xs={12}>
              <Button fullWidth variant={'outlined'} onClick={() => navigate('../')}>
                <Localized
                  dictKey={`${settingsEmailI18nPath}.action.navigate-back.label`}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminMenuContainer>
  );
};
