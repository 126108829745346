import * as React from 'react';
import { navigate } from 'gatsby';

// import { getOrganisationSourceCollectionPath } from 'src/lib/db';

import { useStyles } from 'src/app/context';
// import { SourceCollectionName } from 'src/app/constants';
import { Button, ButtonGroup, DocumentListContainer } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { DaytimeEventDocument } from 'src/app/types';
import { FirestoreCollectionData } from 'src/app/container';
import { doc, getFirestore } from 'firebase/firestore';

// LOCAL IMPORTS

// const documentI18nBasePath = 'organisation:document.activityProductDocument';
// const screenI18nBasePath = 'organisation:org-products';
// const collectionName = SourceCollectionName.PRODUCT;
// const collectionPath = (orgId: string) =>
//   getOrganisationSourceCollectionPath(orgId, collectionName);
// const routeBasepath = `/admin/partner/${collectionName}`;
// const routeBasepath = `/admin/partner/${'product'}`;

type DocumentType = DaytimeEventDocument;

const tableVisualDataPropertyNames2 = [
  // 'id',
  // 'eventDate',
  'startAt',
  'priceSystemId',
  'capacity',
  'offSystemOrderCount',
  'orderCount',
  // 'meta',
];

const documentI18nBasePath2 = 'organisation:document.daytimeEventDocument';
const screenI18nBasePath2 = 'organisation:org-products-events';

// TODO: filter by date/ date range?

export interface ProductEventsContainerProps {
  id?: string; // Reachrouter injects prop
}

// TODO: create Calendar like management

export const ProductEventsContainer = ({ id }: ProductEventsContainerProps) => {
  const { currentOrganisation, openDialog,firebase } = useAppContext();
  const styles = useStyles();

  if (!id) {
    return null;
  }
 ;   

  return (
    <FirestoreCollectionData<DocumentType>
      collectionPath={`product/${id}/daytimeEvent`}
      queryLimit={500} // TODO: pagination
      renderElements={({ data, status }) => {
        // TEMP FIXME: FOR Capacity & offSystemOrderCount
        // TODO: REMOVE
        const remappedData =
          data?.map((item) => ({
            ...item,
            capacity: item.priceSystem?.publicBooking?.maxParticipants || 0,
            // TODO: minParticipants
            offSystemOrderCount: item.offSystemOrderCount || 0,
            orderCount: item.orders?.length || 0,
          })) || [];

          
        return (
          <DocumentListContainer<DocumentType>
            tableData={remappedData || []}
            columnPropertyNames={tableVisualDataPropertyNames2}
            screenI18nBasePath={screenI18nBasePath2}
            tableI18nBasePath={documentI18nBasePath2}
            // onUpdateTableData={() => refetch()}
            onUpdateTableData={console.log}
            features={{
              hasCreate: false,
              hasDelete: false,
              hasDocumentLink: false,
              hasEdit: false,
              hasEditImages: false,
              hasEditTranslations: false,
              hasPreview: false,
            }}
            renderControls={(selectedDocument: DocumentType | null) => {
              return (
                <ButtonGroup fullWidth className={styles.pb__1}>
                  <Button
                    disabled={!selectedDocument?.id}
                    fullWidth
                    variant={'outlined'}
                    onClick={() => navigate(`${selectedDocument?.id || ''}`)}
                  >
                    Event Settings
                  </Button>
                  <Button
                    disabled={!selectedDocument?.id}
                    fullWidth
                    variant={'outlined'}
                    onClick={() => navigate(`${selectedDocument?.id || ''}/bookings`)}
                  >
                    Manage off-system bookings
                  </Button>
                </ButtonGroup>
              );
            }}
          />
        );
        
      }}
    />
  );
};
