import * as React from 'react';

// import FreeCancellationIcon from '@material-ui/icons/ThumbUp';
// import FasterAccessIcon from '@material-ui/icons/Update';
// import FriendlyHelpIcon from '@material-ui/icons/FavoriteBorder';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';


import { FeatureItem, Grid, Localized, Typography } from 'src/app/components';
import { useI18nArray } from 'src/app/hooks';

const i18nPath = 'organisation:/organisation/signup';

const getIconByIndex = (idx: number) => {
  switch (idx) {
    case 0:
      return <ZoomOutMapIcon />;
    case 1:
      return <ImportContactsIcon />;
    case 2:
      return <AttachMoneyIcon />;
    default:
      return null;
  }
};

export const OrganisationSignupInformation = () => {
  const { i18nItems: features } = useI18nArray<{ description: string; title: string }>(
    `${i18nPath}.features`,
    ['description', 'title'],
  );

  const { i18nItems: steps } = useI18nArray<{ description: string; title: string }>(
    `${i18nPath}.steps`,
    ['description', 'title'],
  );

  return (
    <div className='flex flex-col gap-y-12 md:gap-y-20'>
      <div className='flex flex-col md:flex-row gap-4'>
        {features && Array.isArray(features)
          ? features.map((feature, idx) => (
              <div key={`feat_${feature.title}-${idx}`} className='flex-1'>
                <FeatureItem
                  description={feature.description}
                  icon={getIconByIndex(idx)}
                  title={feature.title}
                />
              </div>
            ))
          : null}
      </div>

      <div className='text-center'>
        <h2 className='text-h2 font-bold'>
          <Localized dictKey={`${i18nPath}.action.signup.prompt.title`} />
        </h2>
        <p className='text-p2'>
          <Localized dictKey={`${i18nPath}.action.signup.prompt.subtitle`} />
        </p>
      </div>

      <div className='flex flex-col md:flex-row gap-4'>
        {steps && Array.isArray(steps)
          ? steps.map((step, idx) => (
              <div key={`step_${step.title}-${idx}`} className='flex-1'>
                <FeatureItem
                  description={step.description}
                  icon={
                    <span className='w-10 h-10 flex items-center justify-center rounded-full border border-neutral-900 text-lg font-bold dark:border-neutral-50 md:w-8 md:h-8'>
                      {idx + 1}
                    </span>
                  }
                  title={step.title}
                />
              </div>
            ))
          : null}
      </div>

    </div>
  );
};
