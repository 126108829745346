import * as React from 'react';
import dayjs from 'dayjs';

// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { parseSkuString } from 'src/lib/event';

import { LocalizedErrorIndicator, LoadingIndicator } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';
import { useLocation } from '@reach/router';
import { AppEnvironment } from 'src/app/constants';
import { isEnvironment } from 'src/app/lib';

import { AdminMenuContainer } from 'src/admin/components';

import { AdminScreenProps } from 'src/admin/types';
import { FirestoreCollectionData, Pagination } from 'src/app/container';

import { useStyles } from 'src/app/context';

import { OrganisationOrderDocument } from 'src/app/types';
import { OrganisationOrderList } from './OrganisationOrderList';
import { where } from 'firebase/firestore';
import DataSearchTable from 'src/app/components/table-search/DataSearchTable';

/**
 * OrganisationOrderScreen
 * @version 0.0.0
 * @description
 * @todo
 * @example
 */
export const OrganisationOrderScreen = ({ icon }: AdminScreenProps) => {
  const { currentOrganisation } = useAppContext();
  const { href } = useLocation();
  const [date, setDate] = React.useState<MaterialUiPickersDate | null>();
  const [time, setTime] = React.useState<MaterialUiPickersDate | null>(
    dayjs().set('hour', 13).set('minute', 0),
  );

  const tableHead = [
    {
      name: 'Product Id',
      filterField: 'productId',
      dataName: 'productId',
      fieldType: 'text',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: 'Product Name',
      filterField: 'productName',
      dataName: 'productName',
      fieldType: 'text',
      allowOrder: true,
      isMain: true,
      isSearchable: true
    },
    {
      name: 'Order Id',
      filterField: 'OrderId',
      dataName: 'OrderId',
      fieldType: 'text',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: ' Order Made By',
      filterField: 'userName',
      dataName: 'userName',
      fieldType: 'text',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
   
    {
      name: 'Order Placed',
      filterField: 'createdDate',
      dataName: 'createdDate',
      fieldType: 'dateString',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: '	Number Of Participants',
      filterField: 'participantCount',
      dataName: 'participantCount',
      fieldType: 'int',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: 'Date Of The Event',
      filterField: 'eventDate',
      dataName: 'eventDate',
      fieldType: 'dateString',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: 'Time Of The Event',
      filterField: 'eventTime',
      dataName: 'eventTime',
      fieldType: 'time',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: 'Total Amount',
      filterField: 'totalAmount',
      dataName: 'totalAmount',
      fieldType: 'number',
      allowOrder: true,
      isMain: false,
      isSearchable: true
    },
    {
      name: 'Change Event Parameters',
      filterField: '',
      dataName: 'actionButton',
      buttonName:"changeEventParamater",
      fieldType: 'nil',
      allowOrder: false,
      isMain: false,
      isSearchable: false
    },
    {
      name: 'Cancel Event',
      filterField: '',
      dataName: 'actionButton',
      buttonName:"cancelEvent",
      fieldType: 'nil',
      allowOrder: false,
      isMain: false,
      isSearchable: false
    },
    {
      name: `View Booking Details`,
      filterField: '',
      dataName:"actionButton",
      buttonName: 'viewBookingDetailsSupplier',
      fieldType: 'nil',
      allowOrder: false,
      isMain: false,
      isSearchable: false
    },
  ];
  let filterParamter={
   'supplier': currentOrganisation?.id
  }

  const isProduction = isEnvironment(
    href,
    AppEnvironment.STAGE || AppEnvironment.PRODUCTION,
  );
  // should we provide a filter by product?
  // TODO: 2 querys either for e specific date/time oder for specific year month
  // by exact date & eventTime => over id (could be optional)
  // by TimeStamp range > < (day span / month span / year span)
  // year > month > day => ranges can cascade => should
  return (
    <AdminMenuContainer icon={icon} i18nNS={'organisation'} screenId={'org-orders'}>
      {/** TODO: show aggregated data here */}
      {/* <Grid item xs={12} className={styles.mb__2}>
        <CollectionDateFilter
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
        />
      </Grid> */}
      <>
      <DataSearchTable tableHeadings={tableHead} collectionName={isProduction?'newOrderProd':'newOrderDev'} tableName={'Suppliers Orders List'} filterParamter={filterParamter} />

        {/* <FirestoreCollectionData<OrganisationOrderDocument>
          collectionPath={`organisation/${currentOrganisation?.id || ''}/order`}
          queryLimit={25}
          queryOrderBy={'meta.createdAt'}
          queryOrderDirection={'desc'}
          renderElements={({ data:orderData, status }) => {
            if (status === 'loading') {
              return <LoadingIndicator />;
            }
            if (status === 'error') {
              return (
                <LocalizedErrorIndicator
                  i18nContext={'organisation'}
                  i18nErrorCode={'LOADING_COLLECTION'}
                  i18nErrorAreaCode={'order'}
                />
              );
            }
            let flattenedData:Array<any>=[]
            let organisationFilteredData:Array<any>=[]
            if(status==="success"){
              const flattenItems = (data:any) => {
                return data.flatMap((order:any,) => 
                  order.items.map((item:any,index:any) => ({
                    ...order,
                    item: { ...item },
                  }))
                );
              };          



              function consolidateItems(order) {
                const consolidated:any = {};           
                order.successDetails.purchase_units[0].items.forEach((item) => {
                  const itemValue = parseFloat(item.unit_amount.value)*item.quantity;
                  const itemQuantity = parseInt(item.quantity, 10);
              
                  if (consolidated[item.sku]) {
                    consolidated[item.sku].quantity += itemQuantity;
                    consolidated[item.sku].unit_amount.value = parseFloat(consolidated[item.sku].unit_amount.value) + itemValue;
              
                    if (item.participants) {
                      consolidated[item.sku].participants.push(...item.participants);
                    }
                  } else {
                    consolidated[item.sku] = { 
                      ...item, 
                      unit_amount: { 
                        ...item.unit_amount, 
                        value: itemValue 
                      }, 
                      quantity: itemQuantity 
                    };
              
                    if (item.participants) {
                      consolidated[item.sku].participants = [...item.participants];
                    }
                  }
                });
              
                return {
                  ...order,
                  items: Object.values(consolidated)
                };
              }
          const consolidatedOrders = orderData.map(consolidateItems);
            
         flattenedData = flattenItems(consolidatedOrders);
          organisationFilteredData = flattenedData
           .filter(order => parseSkuString(order.item.sku).organisationId === currentOrganisation.id)
           .map((order, index) => ({
           ...order,
           sl_no: index + 1,
          }));

            }
            return status !== 'success' ? null : (
              <Pagination<OrganisationOrderDocument>
                data={organisationFilteredData}
                className='py-2'
                hideControlsTop
                pageSize={10}
                renderElements={({ data: paginatedData }) => (
                  <>
                  <OrganisationOrderList orders={paginatedData} />
                </>
                )}
              />
            );
          }}
        /> */}
      </>
    </AdminMenuContainer>
  );
};
