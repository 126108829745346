import * as React from 'react';

import { DocumentStringFormFragment } from 'src/app.system/components';

import { FormFragmentContainer, composeFieldName } from 'src/app/components/RHF';

export interface OrganisationSignupBaseInformationFragmentProps {
  i18nBasePath: string;
  name?: string;
}

export const OrganisationSignupBaseInformationFragment = ({
  i18nBasePath,
  name,
}: OrganisationSignupBaseInformationFragmentProps) => {
  return (
    <FormFragmentContainer>
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationName', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationName2', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationAddressStreet', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationAddressStreet2', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationAddressStreetNumber', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationPostOfficeBox', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationZipCode', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationCity', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationCountry', name)}
        i18nBasePath={i18nBasePath}
      />
      {/* <DocumentStringFormFragment
          fieldId={composeFieldName('organisationCountries', name)}
        i18nBasePath={i18nBasePath}
      />
      <DocumentStringFormFragment
        fieldId={composeFieldName('organisationCurrency', name)}
        i18nBasePath={i18nBasePath}
      /> */}
    </FormFragmentContainer>
  );
};
